import React from 'react';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/Container';
import * as _ from 'lodash';
import WarrantyForm from './components/WarrantyForm';

export class WarrantyEditPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Container isAdmin>
        <WarrantyForm id={this.props.match.params.id} addSubWarranty={new URL(window.location.href).searchParams.get('add-sub') == 'true'} />
      </Container>
    );
  }
}

export default inject()(observer(WarrantyEditPage));
