import * as _ from 'lodash';
import BaseStore from './BaseStore';
import config from '../config';
import { http } from '../utils/http';

export class WarrantyTemplateStore extends BaseStore {
  async getAll(params) {
    try {
      const queryString = http.parseQueryString(params);
      const res = await http.get(`${config.api.sims}/qchangv2/v1/warranty-templates` + '?' + queryString);

      if (res.status === 200 && res.data) {
        return res.data;
      }
      return undefined;
    } catch (err) {
      throw err;
    }
  }

  async get(id) {
    try {
      const res = await http.get(`${config.api.sims}/qchangv2/v1/warranty-templates/${id}`);

      if (res.status === 200 && res.data) {
        return res.data;
      }
      return undefined;
    } catch (err) {
      throw err;
    }
  }

  async create(data) {
    try {
      const res = await http.post(`${config.api.sims}/qchangv2/v1/warranty-templates`, {}, data);

      if (res.status === 200 && res.data) {
        return res.data;
      }
      if (!http.isValidStatus(res.status)) {
        throw new Error(res.data.errorMessage);
      }
      return undefined;
    } catch (err) {
      throw err;
    }
  }

  async update(id, data) {
    try {
      const res = await http.patch(`${config.api.sims}/qchangv2/v1/warranty-templates/${id}`, {}, data);

      if (res.status === 200 && res.data) {
        return res.data;
      }

      if (!http.isValidStatus(res.status)) {
        throw new Error(res.data.errorMessage);
      }
      return undefined;
    } catch (err) {
      throw err;
    }
  }

  async delete(id) {
    try {
      const res = await http.delete(`${config.api.sims}/qchangv2/v1/warranty-templates/${id}`);

      if (res.status === 200) {
        return true;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }

  async getWarrantyTemplateConfig() {
    try {
      const res = await http.get(`${config.api.sims}/qchangv2/v1/warranty-templates/config`);

      if (res.status === 200 && res.data) {
        return res.data;
      }
      return undefined;
    } catch (err) {
      throw err;
    }
  }

  async preview(data) {
    try {
      const res = await http.post(`${config.api.sims}/qchangv2/v1/warranty-templates/preview`, data);

      if (res.status === 200 && res.data) {
        return res.data;
      }

      if (!http.isValidStatus(res.status)) {
        throw new Error(res.data.errorMessage);
      }
      return undefined;
    } catch (err) {
      throw err;
    }
  }
}
export default new WarrantyTemplateStore();
