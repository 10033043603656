import _ from 'lodash';
import React from 'react';
import { observer, inject } from 'mobx-react';
import FormGroup from '../../../components/form/FormGroup';
import EnButton from '../../../components/form/EnButton';
import * as stCommon from './stepCommon';
import moment from 'moment';
import numeral from 'numeral';
import datetime from '../../../utils/datetime';
import FontAwesome from '../../../components/common/FontAwesome';
import '../../../../src/assets/css/fontawesome.css';
// import StarRatings from 'react-star-ratings';

export const rating = {
  icon: 'faStar',
  color: '#ffce3d',
};

export class EnInstalledAccepted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 'installation_accepted',
      review: undefined,
      doCollapseReview: true
    };
  }

  npsScoreStyle(npsScore) {
    if (npsScore <= 6) {
      return {
        color: '#AE2E24'
      }
    } else if (npsScore == 7 || npsScore == 8) {
      return {
        color: '#FEA362'
      }
    }
    return {
      color: '#22A06B'
    }
  }

  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }
  getStarIcons(ratingPoints, maxStar) {
    if (!ratingPoints || ratingPoints < 1) {
      return [];
    }
    const stars = ratingPoints > 5 ? 5 : ratingPoints;
    let starIcons = [];
    for (let i = 1; i <= stars; i++) {
      starIcons.push(<FontAwesome className="star-icon-color" iconName={rating.icon} key={`star-${starIcons.length + 1}`} />);
    }
    for (let i = ratingPoints; i < maxStar; i++) {
      starIcons.push(<FontAwesome className="no-star-icon-color" iconName={rating.icon} key={`star-${starIcons.length + 1}`} />);
    }
    return starIcons;
  }

  async componentWillMount() {
    const { id } = this.props.job.toJS().info;
    const review = await this.props.job.getReviewByJobId(id);
    if (review) {
      this.setState({ review });
    }
  }

  getItemAfter(data, index, countData) {
    let header = 'รูปถ่ายหรือไฟล์แนบหลังติดตั้ง';
    if (countData > 1) {
      header = header + ' ครั้งที่ ' + (index + 1);
    }
    let listHeaderInstallationAfter = stCommon.initListItemHeaderOption(header);
    let installationAfterFilesData = stCommon.toListItemData(data.files);
    let listItemAfter = stCommon.getListItem(listHeaderInstallationAfter, installationAfterFilesData, () => { });

    return (
      <div className="row wordWarp" key={index}>
        <div className="col-md-6">
          {listItemAfter}
        </div>
        <div className="col-md-6">
          <FormGroup label="หมายเหตุหลังติดตั้ง">
            {data.remark ? <div>{data.remark}</div> : stCommon.getDisplayEmpty('-')}
          </FormGroup>
        </div>
      </div>
    );
  }

  showReview(review) {
    const jobInfo = this.props.job.toJS().info;
    return (
    <>
        <div className="row">
          <div className="success-page">
            <div style={{position: 'relative'}}>  
              <h4>ความคิดเห็นจากลูกค้า</h4>
              {
                this.state.doCollapseReview &&
                <i
                  className={"fa fa-solid fa-chevron-down"}
                  aria-hidden="true"
                  onClick={() => {this.setState({ doCollapseReview: false})}}
                  style={{position: 'absolute', right: '0px', top: '0px'}}
                />
              }
              {
                !this.state.doCollapseReview && <>
                  <i
                    className={"fa fa-solid fa-chevron-up"}
                    aria-hidden="true"
                    onClick={() => {this.setState({ doCollapseReview: true})}}
                    style={{position: 'absolute', right: '0px', top: '0px'}}
                  />
                  <hr /> 
                  {this.getStarIcons(review.rating, 5)}
                  <div className="row">{review.message ? review.message : '-'}</div>
                  <div className="row">
                    <span>คะแนนความพึงพอใจ: </span>
                    <span style={this.npsScoreStyle(_.get(jobInfo, 'nps.score'))}>{ _.get(jobInfo, 'nps.score') } คะแนน</span>
                  </div>
                  <div className="row">ข้อเสนอแนะจากลูกค้า: { _.get(jobInfo, 'nps.comment') || '-' } </div>
                </>
              }
            </div>
          </div>
          <hr />
        </div >
    </>);
  }

  render() {
    const jobStore = this.props.job.toJS();
    const jobInfo = jobStore.info;
    const { contractorMode, transfer } = this.props;

    let installationBeforeFilesData = stCommon.toListItemData(jobInfo.installationBeforeFiles);
    let listHeaderInstallationBefore = stCommon.initListItemHeaderOption('รูปถ่ายหรือไฟล์แนบก่อนติดตั้ง');
    let listItemBefore = stCommon.getListItem(listHeaderInstallationBefore, installationBeforeFilesData, stCommon.onUploadItemClick.bind(this, 'installationBeforeFiles', jobInfo));

    let statusHistory = stCommon.filterHistoryStatus(jobInfo.statusHistory, 'installation_finished');
    const countData = statusHistory.length;

    const listItemAfter = statusHistory.map((value, index) => {
      return (
        this.getItemAfter(value, index, countData)
      );
    });

    const historyInfo = stCommon.getstatusInfo(jobInfo.statusHistory, this.state.currentStep);
    const date = stCommon.formatDate(historyInfo[0].date);

    let stepDetail;
    if (transfer && transfer.effective_date) {
      // step paid to contractor;
      let transferAmount = '0';
      if (transfer && transfer.amount_exclude_wht) {
        transferAmount = transfer.amount_exclude_wht
      }
      const amountTxt = numeral(transferAmount).format('0,0.00');
      let title = 'เตรียมการจ่ายเงินให้ช่าง';
      let textDate = '';
      const today = moment(moment().format('YYYY/MM/DD'));
      const compareDate = moment(transfer.effective_date).add('days', 1).format('YYYY/MM/DD');
      if (today.isSameOrAfter(compareDate)) {
        title = 'จ่ายเงินให้ช่างเรียบร้อยแล้ว';
        textDate = `เมื่อวันที่ ${datetime.formatWithOffset(transfer.effective_date, 'DD/MM/YYYY', 7)}`;
      } else {
        textDate = `ช่างจะได้รับเงินภายในวันที่ ${datetime.formatWithOffset(transfer.effective_date, 'DD/MM/YYYY', 7)}`;
      }
      let textSpan = <div><p>จำนวนเงิน <b>{amountTxt}</b> บาท</p><p>{textDate}</p></div>;
      stepDetail = stCommon.showContentStepStatus('fa fa-usd', title, textSpan, 'orange');
    } else {
      // step customer review finished
      stepDetail = stCommon.showStepStatus('fa fa-check-circle-o', 'ลูกค้ายอมรับการติดตั้งเรียบร้อย', date, '#0fad00');
    }
    return (
      <div>
        {stepDetail}
        {this.state.review && !contractorMode && this.showReview(this.state.review)}
        {stCommon.showBasicInfo(jobInfo, contractorMode)}
        <div className="job-info">
          <div className="title">
            <p>รายละเอียดงานก่อนติดตั้ง</p>
          </div>
        </div>
        <div className="row wordWarp">
          <div className="col-md-6">
            {listItemBefore}
          </div>
          <div className="col-md-6">
            <FormGroup label="หมายเหตุก่อนติดตั้ง">
              {jobInfo.installationBeforeRemark ? <div>{jobInfo.installationBeforeRemark}</div> : stCommon.getDisplayEmpty('-')}
            </FormGroup>
          </div>
        </div>
        <hr />
        <div className="job-info">
          <div className="title">
            <p>รายละเอียดงานหลังติดตั้ง</p>
          </div>
        </div>
        {listItemAfter}
        <div className="row">
          <div className="col-md-12">
            <EnButton id="back-button" className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
              <i className="fa fa-reply btn-icon" aria-hidden="true" />กลับ
            </EnButton>
          </div>
        </div>
      </div >

    );
  }
}

export default inject('auth', 'job')(observer(EnInstalledAccepted));
