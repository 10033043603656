import React, { Component } from 'react';
import moment from 'moment';
import EnToggle from '../../../../components/form/EnToggle';
import config from '../../../../config';
import swal from 'sweetalert2';
import { isRole, ROLE } from '../../../../utils/permission';
import { broadcastRecipientType } from '../../../../utils/data';
import WarrantyTemplateStore from '../../../../stores/WarrantyTemplateStore';
import Loader from '../../../../components/common/Loader';
import { defaultConfirmOption } from '../../../../utils/common';

class WarrantyRowTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      editMode: isRole(ROLE.INSTALLER_SUPER_ADMIN),
      name: '',
      id: '',
    };
  }

  componentDidMount() {
    this.setState({ ...this.props.warranty });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ ...this.props.warranty });
    }
  }

  getIsPublishedIcon(status) {
    return status ? (
      <div style={{ backgroundColor: '#D6E7D3', color: '#008A4F', borderRadius: '16px', width: '74px', margin: 'auto' }}>เผยแพร่</div>
    ) : (
      <div style={{ backgroundColor: '#FFEAEC', color: '#EB3D32', borderRadius: '16px', width: '74px', margin: 'auto' }}>ไม่เผยแพร่</div>
    );
  }

  getRecipientType(type) {
    if (!type) return '';
    return broadcastRecipientType.find((item) => item.value === type).label;
  }

  convertDate(date, time) {
    return (
      <span>
        <span>{date && moment(date).add(543, 'year').format('DD MMM YY')}</span>
        <br />
        {time && <span>{time} น.</span>}
      </span>
    );
  }

  async handleChangeToggle(id, value) {
    const result = await swal.fire({
      title: 'ยืนยันการเปลี่ยนแปลงสถานะ',
      html: 'คุณต้องการเปลี่ยนแปลงสถานะหรือไม่?',
      imageWidth: 60,
      imageHeight: 60,
      width: 400,
      height: 300,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: { popup: 'swal2-border' },
      ...defaultConfirmOption({
        customClass: {
          title: 'primary-text-color',
        },
      }),
    });
    if (!result.value) return null;
    this.setState({ isLoading: true });

    const body = value ? { is_active: true } : { is_active: false };
    await WarrantyTemplateStore.update(id, body);
    this.props.fetchData();

    this.setState({ isLoading: false });
  }

  render() {
    const { _id, code, name, version, started_date, created_by, is_active, editMode, isLoading } = this.state;
    const { isParent, hasSubWarranty, isExpand, onExpand } = this.props;
    return (
      <>
        <Loader show={isLoading} />
        <tr key={_id} style={{ backgroundColor: !isParent ? '#F3FAFF' : '' }}>
          <td className="text-center" width="2%">
            {isParent && hasSubWarranty && (
              <div onClick={() => onExpand(_id)}>
                <span className={`fa fa-chevron-${isExpand ? 'down' : 'right'}`} style={{ color: 'darkgray', fontSize: '14px', cursor: 'pointer' }} />
              </div>
            )}
          </td>
          <td width="5%">{code}</td>
          <td width="15%" style={{ wordBreak: 'break-word' }}>
            {name}
          </td>
          <td width="5%" className="text-center">
            {version}
          </td>
          <td width="10%" className="text-center">
            {this.convertDate(started_date)}
          </td>
          <td width="10%" className="text-center" style={{ wordBreak: 'break-word' }}>
            {created_by}
          </td>
          <td width="5%" className="text-center">
            <div className="d-flex justify-content-center">
              <EnToggle disabled={!editMode} checked={is_active} onChange={(value) => this.handleChangeToggle(_id, value)} />
            </div>
          </td>
          <td width="15%" className="text-center">
            <button
              className="btn btn-xs"
              title="ดู"
              data-tip="ดูรายละเอียด"
              onClick={() => this.props.history.push(`${config.publicUrl}/qchang/warranty/${_id}`)}
            >
              <span className="fa fa-eye" aria-hidden="true" />
            </button>

            <button
              disabled={!editMode}
              className="btn btn-xs"
              title="แก้ไข"
              data-tip="แก้ไข"
              onClick={() => this.props.history.push(`${config.publicUrl}/qchang/warranty/${_id}/edit`)}
            >
              <span className="fa fa-pencil" aria-hidden="true" />
            </button>

            <button
              disabled={!editMode}
              className="btn btn-xs"
              title="ลบ"
              data-tip="ลบ"
              onClick={() => this.props.onDelete(_id)}
              style={{
                backgroundColor: '#F44336',
                color: 'white',
              }}
            >
              <span className="fa fa-trash btn-icon" aria-hidden="true" />
            </button>
          </td>
        </tr>
      </>
    );
  }
}

export default WarrantyRowTable;
