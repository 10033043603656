import React from 'react';
import config from '../../../../config';
import { isRole, ROLE } from '../../../../utils/permission';

const WarrantyHeaderTable = ({ history }) => {
  return (
    <thead>
      <tr>
        <th width="3%"></th>
        <th width="5%" className="text-center">
          รหัส
        </th>
        <th width="15%" className="text-center">
          ชื่อใบรับประกัน
        </th>
        <th width="5%" className="text-center">
          เวอร์ชัน
        </th>
        <th width="10%" className="text-center">
          วันที่เริ่มต้น
        </th>
        <th width="10%" className="text-center">
          สร้างโดย
        </th>
        <th width="5%" className="text-center">
          สถานะการใช้งาน
        </th>
        <th width="15%" className="text-center">
          <button
            disabled={isRole(ROLE.INSTALLER_SUPER_ADMIN) ? false : true}
            className="btn btn-success"
            title="สร้างใหม่"
            data-tip="สร้างใหม่"
            onClick={() => history.push(`${config.publicUrl}/qchang/warranty/add`)}
          >
            <span className="fa fa-plus btn-icon" aria-hidden="true" />
            สร้างใหม่
          </button>
        </th>
      </tr>
    </thead>
  );
};

export default WarrantyHeaderTable;
