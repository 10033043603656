import { isNil } from 'lodash';
import React, { Component } from 'react';

import EnText from './EnText';
import FormGroup from './FormGroup';

export class EnTextFormGroup extends Component {
  onChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  onKeyDown = (e) => {
    if (!this.props.noDecimals || this.props.type !== 'number') {
      return null;
    }
    // Prevent 'e', '.', '+', '-' from being typed
    if (['e', '.', '+', '-'].includes(e.key)) {
      e.preventDefault();
    } else if (e.target.value[0] == '0' && e.key != 'Backspace') {
      e.preventDefault();
      e.target.value = e.key;
    }
  };

  onBlur = (e) => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  render() {
    let {
      label,
      value,
      validations,
      type,
      onKeyPress,
      name,
      size,
      maxLength,
      containerClassName,
      notRequired,
      id,
      placeholder,
      min,
      className,
      labelClassName,
      isError,
      errorMessage,
      style,
      icon,
    } = this.props;

    let options = {
      label,
      labelClassName,
      notRequired,
      validations: !isNil(validations) && Array.isArray(validations),
      containerStyle: style || {},
    };

    let inputOptions = {};
    if (name) {
      inputOptions['name'] = name;
    }
    if (size) {
      inputOptions['size'] = size;
    }
    if (maxLength) {
      inputOptions['maxLength'] = maxLength;
    }
    if (id) {
      inputOptions['id'] = id;
    }
    if (min) {
      inputOptions['min'] = min;
    }

    if (className) {
      inputOptions['className'] = className;
    }

    return (
      <FormGroup containerClassName={containerClassName} {...options}>
        {icon && (
          <span style={{ padding: '5px' }}>
            <i className={icon} aria-hidden="true" />
          </span>
        )}
        <EnText
          {...inputOptions}
          placeholder={placeholder}
          type={type}
          disabled={this.props.disabled}
          ref={(e) => {
            this.input = e;
          }}
          value={value || ''}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          validations={validations}
          onKeyPress={onKeyPress}
          onBlur={this.onBlur}
        />
        {this.props.suffix && (
          <span
            className="custom-suffix"
            style={{
              color: 'gray',
            }}
          >
            {this.props.suffix}
          </span>
        )}
        {this.props.children}
        {isError && <span className="input-error">{errorMessage}</span>}
      </FormGroup>
    );
  }
}

export default EnTextFormGroup;
