import React from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import numeral from 'numeral';
import * as _ from 'lodash';
import EnDateRangePicker from '../../../components/form/EnDateRangePicker';
import FormGroup from '../../../components/form/FormGroup';
import EnToggle from '../../../components/form/EnToggle';
import { observer, inject } from 'mobx-react';
import EnButton from '../../../components/form/EnButton';
import Notification from '../../../components/common/Notification';
import * as stCommon from './stepCommon';
import swal from 'sweetalert2';
import history from '../../../utils/history';
import config from '../../../config';
import datetime from '../../../utils/datetime';
import creatingQuotationSvg from '../../../assets/img/creating-quotation.svg';
import SimplePagination from '../../../components/common/SimplePagination';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import Loader from '../../../components/common/Loader'
import http from '../../../utils/http';
import { isAllow } from '../../../utils/permission';

class EnStepSurveyQuotationManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: ['survey_finished', 'quotation_management', 'installation_requested', 'paid_to_contractor'],
      nextStep: 'installation_requested',
      jobInsId: undefined,
      stateReasonNoQuotation: true,
      viewOnly: false,
      hasBeenPaid: false,
      isLoading: true,
      draftQuotationItems:[],
      publicDraftQuotations: [],
      quotationInfo: {},
      query: {
        draftQuotationNo:'',
        limit: 5,
        page: 1,
        totalPages: 1,
        totalItems: 1
      },
      statusDraft: [
        {
          label: 'ฉบับร่าง',
          value: false,
        },
        {
          label: 'ฉบับจริง',
          value: true,
        },
      ],
      jobI: null,
      orderId: undefined,
      promotionText: undefined,
      trackingUrl: '',
    };
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  async componentDidMount() {
    try {
      const jobInfo = this.props.job.toJS().info;
      let jobI = null;
      let isPaid = false;
      const { superAdminMode } = this.props;

      if (jobInfo && jobInfo.storeId && jobInfo.refCode) {
        if (
          jobInfo.storeId === config.qchang.storeId ||
          (superAdminMode && jobInfo.storeId === config.qchang.storeId)
        ) {
          const checkPromotion = false;
          const orderInfo = await this.props.job.getOrderTracking(
            jobInfo.refCode,
            checkPromotion
          );
          if (!_.isNil(orderInfo) && !_.isEmpty(orderInfo)) {
            this.setState({ orderId: orderInfo._id, orderInfo });
          }
        }
      }

      let trackingUrl = `${config.qchang.consumerWeb}/tracking/order/${this.state.orderId}?jobId=${jobInfo.id}`;
      if (jobInfo.jobType === 'S') {
        jobI = await this.props.job.getInstallationJobInfo(jobInfo.jobCode, false);
        if (jobI) {
          this.setState({
            installationUrl: `${config.publicUrl}/superadmin/jobs/${jobI._id.toString()}`,
            installationJob: jobI,
            installationJobId: jobI._id.toString(),
          });
          trackingUrl = `${config.qchang.consumerWeb}/tracking/order/${this.state.orderId}?jobId=${jobI._id}`;
        }
      }
      this.setState({ trackingUrl: trackingUrl });

      await this.props.quotation.getOptionQuotation(jobInfo.jobCode);
      const quotationInfo = this.props.quotation.toJS().quotationInfo;
      const jobIFirstQuotation = _.get(jobI, 'quotation.0');
      
      if (_.get(jobI, 'is_paid') === true) {
        isPaid = true;
      } else if (_.get(jobIFirstQuotation, 'periods.length') > 0) {
        const periodPaid = jobIFirstQuotation.periods.filter( period => period.status === 'paid' );
        isPaid = !!(periodPaid.length > 0);
      } else if (_.get(quotationInfo, 'sub_quotation.length') > 0) {
        const searchedJobs = await this.props.job.getJobBySearch({ isSuperAdmin: true, query: quotationInfo.job_code });
        if (Array.isArray(searchedJobs)) {
          const paidJobInstallment = searchedJobs.filter(searchedJob => searchedJob.installment && searchedJob.is_paid);
          isPaid = !!(paidJobInstallment.length > 0);
        }
      }
      if (isPaid){
        this.setState({viewOnly: true});
      }

      this.setState({
        query: {
          jobCode:quotationInfo.job_code,
          ...this.state.query
        },
        hasBeenPaid: isPaid,
        jobI,
        quotationInfo
      });
      this.getPaginationDraft();
    } catch (error) {}
  }

  async getPaginationDraft(){
    this.setState({ isLoading: true })
    try {
      const { items, limit, page, totalPages, totalItems } = await this.props.quotation.getPaginatedDraftQuotations(this.state.query)
      this.setState({
        draftQuotationItems: items,
        query: {
          ...this.state.query,
          limit,
          page,
          totalPages,
          totalItems,
        },
      });
    } finally {
      this.setState({ isLoading: false })
    }
  }

  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  async createLogQuotationsByComparingDraftQuotation() {
    const oldDraftQuotations = this.state.quotationInfo.draft_quotation;
    const currentDraftQuotations = this.props.quotation.toJS().quotationInfo.draft_quotation || [];
    const jobInfo = this.props.job.toJS().info;
    for (let draftQuotation of currentDraftQuotations) {
      let oldDraftQuotation = oldDraftQuotations.find(oldDQ => oldDQ.no === draftQuotation.no);
      if (_.isEqual(draftQuotation, oldDraftQuotation) || !oldDraftQuotation) continue;
      await this.props.quotation.logQuotation({
        users: this.props.auth.getUsernameOrEmail(),
        quotation_rev_no: draftQuotation.rev_no,
        quotation_no: draftQuotation.no,
        job_id: jobInfo.id,
        job_code: jobInfo.jobCode,
        is_public: draftQuotation.is_public,
        is_final: draftQuotation.is_final,
        is_delete: draftQuotation.is_delete,
      });
    }
  }

  async onSubmit() {
    try {
      if (this.props.onSubmit) {
        await this.props.quotation.updateDraft();
        await this.createLogQuotationsByComparingDraftQuotation();
        const draftQuotation = this.props.quotation.toJS().quotationInfo.draft_quotation || [];
        const isFinal = _.find(draftQuotation, draft=> draft.is_final===true);
        const jobInfo = this.props.job.toJS().info;
        const isSetUpInstallationDate = jobInfo.installationStartDate && jobInfo.installationEndDate
        // go next step
        if (isFinal && (isSetUpInstallationDate)) {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          this.noti.success('บันทึกข้อมูลสำเร็จแล้ว');
          window.location.reload();
        }
      }
    } catch (error) {
      console.error('error.message ------------->>', error.message);
      this.noti.error('บันทึกข้อมูลไม่สำเร็จ');
    }
  }

  // onShowNoQuotation() {
  //   const regexSpecialCharacters = /^[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]+$/;
  //   const regexDashes = /^(-)+$/;
  //   swal.fire({
  //     input: 'textarea',
  //     title: 'ระบุเหตุผลที่ไม่ต้องออกใบเสนอราคา',
  //     inputPlaceholder: 'กรุณากรอกข้อความ',
  //     showCancelButton: true,
  //     confirmButtonText: 'บันทึก',
  //     cancelButtonText: 'ยกเลิก',
  //     inputValidator: (value) => {
  //       if (regexSpecialCharacters.test(value)) {
  //         return '* กรุณากรอกข้อมูลให้ถูกต้อง';
  //       }
  //     },
  //     onOpen: function () {
  //       swal.disableConfirmButton();
  //       swal.getInput().addEventListener('keyup', function (e) {
  //         const value = e.target.value;
  //         if (value.trim() === '' || value.length < 5 || regexDashes.test(value)) {
  //           swal.disableConfirmButton();
  //         } else {
  //           swal.enableConfirmButton();
  //         }
  //       });
  //     },
  //   }).then((result) => {
  //     if (result.value) {
  //       const toDay = moment().format();
  //       this.props.job.saveReasonNoQuotation(result.value);
  //       this.props.job.saveCustomerApprovedDate(toDay);
  //       this.onSubmit();
  //     }
  //   });
  // }

  async gotoQuotationPage() {
    swal.fire({
      title: 'โปรดยืนยันการสร้างใบเสนอราคาใหม่',
      text: `ระบบจะทำการสร้างใบเสนอราคาใหม่`,
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      showLoaderOnConfirm: true,
    }).then( async (result) => {
      if(result.value) {
        const jobInfo = this.props.job.toJS().info;
        const jobI = await this.props.job.getInstallationJobInfo(jobInfo.jobCode, true);
        this.setState({ installationUrl: `${config.publicUrl}/superadmin/jobs/${jobI._id.toString()}` });
        history.push(`${config.publicUrl}/superadmin/jobs/${jobI._id.toString()}/quotation/${jobInfo.id}?isNewDraft=true`);
      }
    }).catch((error)=>{
      this.noti.error('ไม่สามารถสร้างใบเสนอราคาได้ในขณะนี้ กรุณาลองใหม่ในภายหลัง');
      console.log('gotoQuotationPage error ------------->>', error);
    })
  }

  getUrl(contractorMode, superAdminMode, jobId, contractorId) {
    let url = config.publicUrl;
    if (contractorMode) {
      url += '/contractors/' + contractorId + '/calendar';
    } else if (superAdminMode) {
      url += '/superadmin';
    } else {
      url += '/admin/calendar';
    }
    url += '/jobs/' + jobId;
    return url;
  }

  async onToggleDraft(index, isPublic) {
    let draftQuotationItems = this.state.draftQuotationItems;
    _.set(draftQuotationItems,`[${index}].is_public`, !isPublic);
    this.setState(draftQuotationItems)
    const draftNo = _.get(draftQuotationItems,`[${index}].draft_no`,'')
    const { draft_quotation: draftQuotation = [] } = this.props.quotation.toJS().quotationInfo;
    const indexDraft = _.findIndex(draftQuotation, draft => draft.draft_no === draftNo);
    this.props.quotation.saveDraftPublic(indexDraft, !isPublic);
  }

  onDeleteDraft(isDelete, index) {
    const quotation = this.props.quotation.toJS().quotationInfo;
    if (!quotation) return;
    const { draft_quotation: draftQuotation = [] } = quotation;
    const docForDelete = draftQuotation[index].files[draftQuotation[index].files.length-1];
    const jobInfo = this.props.job.toJS().info;
    swal.fire({
      title: 'โปรดยืนยันการลบใบเสนอราคา',
      text: `ลบใบเสนอราคา ${docForDelete.name}`,
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      showLoaderOnConfirm: true,
    }).then( async (result) => {
      if(result.value) {
        let quotation = this.props.quotation.toJS().quotationInfo;
        if (!quotation) return;
        let { draft_quotation: draftQuotation = [] } = quotation;

        const draftQuotationItems = this.state.draftQuotationItems;
        _.set(draftQuotationItems,`[${index}].is_delete`, isDelete);
        this.setState(draftQuotationItems);
        // find index draft_quotation
        const draftNo = _.get(draftQuotationItems,`[${index}].draft_no`,'')
        const indexDraft = _.findIndex(draftQuotation, draft => draft.draft_no === draftNo);
        this.props.quotation.deleteDraft(indexDraft, isDelete);
        // prepare data to payload
        draftQuotation = this.props.quotation.toJS().quotationInfo.draft_quotation;
        const payload = {
          job_code: jobInfo.jobCode,
          draft_no: draftQuotation[indexDraft].draft_no,
          is_delete: draftQuotation[indexDraft].is_delete,
        }
        await this.props.quotation.deleteDraftQuotation(payload);
        await this.props.quotation.logQuotation({
          users: this.props.auth.getUsernameOrEmail(),
          quotation_rev_no: +draftQuotation[indexDraft].rev_no,
          quotation_no: draftQuotation[indexDraft].no,
          job_id: jobInfo.id,
          job_code: jobInfo.jobCode,
          is_public: draftQuotation[indexDraft].is_public,
          is_final: draftQuotation[indexDraft].is_final,
          is_delete: draftQuotation[indexDraft].is_delete
        });
        this.getPaginationDraft();
      }
    }).catch((error)=>{
      this.noti.error('ลบข้อมูลไม่สำเร็จ');
      console.log('onDeleteDraft error ------------->>', error.message);
      this.getPaginationDraft();
    })
  }
  onEditDraft(draftNo, fileName, isFinal) {
    swal.fire({
      title: 'โปรดยืนยันการแก้ไขใบเสนอราคาเดิม',
      text: `ระบบจะทำการแก้ไขใบเสนอใบที่ ${draftNo} : ${fileName}`,
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      showLoaderOnConfirm: true,
    }).then( async (result) => {
      if(result.value) {
        const jobInfo = this.props.job.toJS().info;
        const jobI = await this.props.job.getInstallationJobInfo(jobInfo.jobCode, true);
        this.setState({ installationUrl: `${config.publicUrl}/superadmin/jobs/${jobI._id.toString()}` })
        if (isFinal) {
          const quotationNo = _.get(_.first(jobI.quotation), 'no');
          history.push(`${config.publicUrl}/superadmin/jobs/${jobI._id.toString()}/quotation?quotationNo=${quotationNo}&fromQuotationManagement=true`);
        } else {
          history.push(`${config.publicUrl}/superadmin/jobs/${jobI._id.toString()}/quotation/${jobInfo.id}?draftNo=${draftNo}&isFinal=${isFinal}&fromQuotationManagement=true`);
        }
      }
    }).catch((error)=>{
      console.log('gotoQuotationPage error ------------->>', error.message);
    })
  }

  handleSelectDraft(isFinal, index) {
    let draftQuotationItems = this.state.draftQuotationItems;
    _.set(draftQuotationItems,`[${index}].is_final`, isFinal);
    if(isFinal){
      _.set(draftQuotationItems,`[${index}].is_public`, isFinal);
      // reset all is_public
      for(let i = 0; i< draftQuotationItems.length; i++){
        if(i!==index && isFinal){
          draftQuotationItems[i].is_public = false;
        }
      }
    }
  
    this.setState(draftQuotationItems);
    const draftNo = _.get(draftQuotationItems,`[${index}].draft_no`,'')
    const { draft_quotation: draftQuotation = [] } = this.props.quotation.toJS().quotationInfo;
    const indexDraft = _.findIndex(draftQuotation, draft => draft.draft_no === draftNo);
    this.props.quotation.saveDraftFinal(indexDraft, isFinal);
  }

  getLinkDocumentQuotation(file){
    return (
      <a target='_blank' href={file.url}>
        {file.name}
      </a>
    )
  }

  getContentDraft() {
    const quotation = this.props.quotation.toJS().quotationInfo;
    if (!quotation) return;
    const { sub_quotation: subQuotation = undefined } = quotation;
    const indexIsFinal = _.findIndex(this.state.draftQuotationItems, (draft) => draft.is_final === true);
    const isInstallment = subQuotation && subQuotation.length > 0;
    return (this.state.draftQuotationItems || []).map((draft, i) => {
      const paymentText = draft.periods.length === 1 ? 'ชำระเต็มจำนวน' : `แบ่งชำระ ${draft.periods.length} งวด`;
      const isDelete = draft.is_delete;
      const file = draft.files && draft.files.length > 0 ? draft.files[draft.files.length - 1] : []
      return !isDelete && (
        <tr key={i}>
          <td>
            <DropdownButton
              disabled={(indexIsFinal >= 0 && indexIsFinal !== i) || this.state.viewOnly}
              onSelect={this.onSubmit.bind(this)}
              bsStyle='default'
              title={indexIsFinal === i ? this.state.statusDraft[1].label : this.state.statusDraft[0].label}
              id='dropdown-switch-tole'
              className={indexIsFinal === i ? 'switch-role is-final' : 'switch-role'}>
              {this.state.statusDraft.map(
                (status) =>
                  status && (
                    <MenuItem
                      key={status.value}
                      eventKey={status.value}
                      onSelect={this.handleSelectDraft.bind(this, status.value, i)}
                      active={status.value === draft.is_final}
                      className='switch-role-text'>
                      <div className={status.value === draft.is_final ? 'active' : ''}>{status.label}</div>
                    </MenuItem>
                  )
              )}
            </DropdownButton>
          </td>
          <td>
            <div>{this.getLinkDocumentQuotation(file)}</div>
            <div>Revision: {draft.rev_no}</div>
          </td>
          <td>
            <div>{paymentText}</div>
            <div>รวม {draft.grand_total} บาท</div>
          </td>
          <td>
            <div>
              แก้ไข: {moment(draft.updated_date).format('DD-MM-YYYY')} {draft.updated_by}
            </div>
            <div>
              สร้าง: {moment(draft.created_date).format('DD-MM-YYYY')} {draft.created_by}
            </div>
          </td>
          <td className='text-center'>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}>
              <EnToggle
                disabled={(indexIsFinal >= 0 && indexIsFinal !== i) || this.state.viewOnly}
                checked={draft.is_public}
                onChange={this.onToggleDraft.bind(this, i, draft.is_public)}
              />
            </div>
          </td>
          <td className='text-center'>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}>
              <button
                disabled={(indexIsFinal >= 0 || this.state.viewOnly) && indexIsFinal !== i}
                className='btn btn-sm btn-warning'
                title='แก้ไข'
                data-tip='แก้ไข'
                onClick={this.onEditDraft.bind(this, draft.draft_no, draft.files? draft.files[draft.files.length-1].name: '', draft.is_final)}>
                <i className='fa fa-pencil' aria-hidden='true' />
              </button>
              <button
                disabled={(indexIsFinal >= 0) || this.state.viewOnly}
                className='btn btn-sm btn-danger'
                title='ลบ'
                data-tip='ลบ'
                onClick={this.onDeleteDraft.bind(this, true, i)}>
                <i className='fa fa-trash' aria-hidden='true' />
              </button>
            </div>
          </td>
        </tr>
      );
    });
  }

  displayDraftQuotationTable() {
    return (
      <div className='row'>
        <div className='col-md-12'>
          <table className='table table-bordered table-hover has-pagination'>
            <thead>
              <tr>
                <th width='10%' className='text-left'>
                  สถานะเอกสาร
                </th>
                <th width='30%' className='text-left'>
                  ชื่อเอกสาร
                </th>
                <th width='20%' className='text-left'>
                  ประเภท/ ยอดชำระ
                </th>
                <th width='20%' className='text-left'>
                  แก้ไขล่าสุด / สร้าง โดย
                </th>
                <th width='10%' className='text-center'>
                  แสดงใบเสนอราคา
                </th>
                <th width='10%' className='text-left'>
                  เมนูทำรายการ
                </th>
              </tr>
            </thead>
            <tbody>{this.getContentDraft()}</tbody>
          </table>
        </div>
      </div>
    );
  }

  displayInstallmentDate() {
    const { contractorMode } = this.props;
    const jobStore = this.props.job.toJS();
    const jobInfo = jobStore.info;
    const isShowinstallationDateRangePicker = this.state.installationJob
      ? this.state.installationJob.quotation && this.state.installationJob.quotation.length
      : false;
    const isInstallment = this.state.installationJob ? (this.state.installationJob.installment ? true : false) : false;
    const installationDateRangePicker = (
      <EnDateRangePicker
        withPortal
        numberOfMonths={1}
        // disabled={(jobInfo.status !== 'quotation_management' && jobInfo.status !== 'survey_finished' && jobInfo.status !== 'paid_to_contractor')}
        ref={(e) => {
          this.instDateRange = e;
        }}
        openDirection='up'
        minimumNights={0}
        startDate={jobInfo.installationStartDate ? moment(jobInfo.installationStartDate) : null}
        endDate={jobInfo.installationEndDate ? moment(jobInfo.installationEndDate) : null}
        isDayBlocked={this.checkInstDayBlock.bind(this)}
        isOutsideRange={(date) => {
          const now = moment().utcOffset(7).startOf('day'); // Current date with UTC offset of +7
          const futureDate = now.clone().add(550, 'days'); // 550 days from now

          return !date.isBetween(now, futureDate, 'day', '[]');
        }}
        onDateChange={this.onChangeDateRange.bind(this)}
        startDatePlaceholderText='วันที่เริ่มต้น'
        endDatePlaceholderText='วันที่สิ้นสุด'
      />
    );

    if (
        isShowinstallationDateRangePicker &&
        !contractorMode &&
        !isInstallment
      ) {
        return (
          <div
            className='row'
            style={{
              margin: '20px -15px 20px -15px',
            }}>
            <FormGroup containerClassName='col-md-12' label='วันที่ติดตั้ง'>
              {installationDateRangePicker}
            </FormGroup>
          </div>
        );
    }
    return (<></>);
  }

  checkInstDayBlock(date) {
    if (this.props.checkInstDayBlock) {
      return this.props.checkInstDayBlock(date);
    } else {
      const { unavailableInstDate } = this.props;
      if (Array.isArray(unavailableInstDate) && unavailableInstDate.length) {
        const dateBlock = unavailableInstDate.find((u) => datetime.isEqual(date, u));
        return !!dateBlock;
      } else {
        return false;
      }
    }
  }

  onChangeDateRange(installationStartDate, installationEndDate) {
    const dateRange = datetime.GetBetweenDates(installationStartDate, installationEndDate);
    const unavailableInstDate = dateRange.filter((d) => {
      return this.checkInstDayBlock(d);
    });
    if (unavailableInstDate && unavailableInstDate.length > 0) {
      installationStartDate = undefined;
      installationEndDate = undefined;
    }
    this.props.job.saveMultiInfo({
      installationStartDate,
      installationEndDate,
    });
  }

  onUpdateQuery = (key, value) => {
    const query = this.state.query
    _.set(query, key, value)
    this.setState(query)
  }

  onSetPage = (page) => {
    this.onUpdateQuery('page', page)
    this.getPaginationDraft();
  }


  displayPagination() {
    return (
      <div className='row'>
        <div className='col-md-12'>
         <SimplePagination pagination={this.state.query} onSetPage={this.onSetPage}/>
        </div>
      </div>
    );
  }

  async sendPublicDraftQuotationToCustomerEmail(publicDraftQuotations) {
    const info = this.props.job.toJS().info;
    try {
      const requestBody = {
        jobCode: info.jobCode,
        publicDraftQuotations,
      }
      const response = await http.post(`${config.api.sims}/v1/quotations/sendPublicQuotationsToCustomer`, requestBody);
      if (response.status !== 200) {
        throw new Error(_.get(response, 'data.message'));
      }
      this.noti.success(`ผู้รับ ${info.email}`, 'ส่งเมล์สำเร็จ');
    } catch (error) {
      console.log('updateDateQuotation error ------------->>', error);
      this.noti.error(error.message, 'พบข้อผิดพลาด');
    }
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.state.trackingUrl);
  }

  render() {
    const { isLoading } = this.state;
    const { contractorMode, superAdminMode } = this.props;
    const jobStore = this.props.job.toJS();
    const jobInfo = jobStore.info;
    const contractorId = jobInfo.contractorId;
    const enableNextStep = this.state.currentStep.filter((status) => status === jobInfo.status);
    const installationUrl = this.getUrl(contractorMode, superAdminMode, this.state.installationJobId, contractorId);
    const channel = this.props.auth.getChannelName();
    const hasNoQuotationReason = jobInfo.reasonNoQuotation;
    let isSCGHome = channel === 'SCGHOME Online';

    const quotation = this.props.quotation.toJS().quotationInfo;
    if (!quotation) return;
    const { draft_quotation: draftQuotation = [] } = quotation;
    const indexIsFinal = _.findIndex(draftQuotation, (draft) => draft.is_final === true);
    const canCreateDraft = !!(indexIsFinal < 0) || this.state.hasBeenPaid;
    const publicDraftQuotation = draftQuotation.filter(dq => dq.is_public == true);

    return (
      <div>
        <Loader show={isLoading} />
        <Notification
          ref={(ref) => {
            this.noti = ref;
          }}
        />
        <div className="row">
          <div
            style={{
              display: 'flex',
              padding: '0 20px 0 0',
              justifyContent: 'space-between', 
              alignItems: 'center', 
              flexWrap: 'wrap', 
            }}
          >
            {/* Left Section: Quotation Text and Button */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!contractorMode && (
                <>
                  <div
                    style={{
                      margin: '0 20px 0 20px',
                      fontSize: '20px',
                    }}
                  >
                    ใบเสนอราคา (Quotation)
                  </div>
                  <EnButton
                    disabled={!canCreateDraft}
                    id="open-quotation-button"
                    className="btn-primary btn-quotation"
                    onClick={this.gotoQuotationPage.bind(this)}
                  >
                    <i className="fa fa-file-text-o btn-icon" aria-hidden="true" />
                    ทำใบเสนอราคา
                  </EnButton>
                </>
              )}
            </div>

            {/* Right Section: Check Status and Copy Links */}
            {this.state.orderId && !contractorMode && isAllow('VIEW_JOB_TRACKING_PROCESS') && (
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                <a
                  href={this.state.trackingUrl}
                  target="_blank"
                  style={{ marginRight: '10px' }}
                >
                  <i className="fa fa-truck" aria-hidden="true" />
                  &nbsp;&nbsp;ตรวจสอบสถานะ
                </a>
                <a onClick={() => this.copyToClipboard()} style={{ cursor: 'pointer', marginLeft: '10px' }}>
                  <i className="fa fa-copy" aria-hidden="true" />
                  &nbsp;&nbsp;คัดลอก
                </a>
              </div>
            )}
          </div>
        </div>
        {!contractorMode && this.displayDraftQuotationTable()}
        {!contractorMode && this.displayPagination()}
        {!contractorMode && this.displayInstallmentDate()}
        <div className="row">
          {contractorMode && (
            <>
              <div style={{ textAlign: 'center', padding: '50px 0 70px 0' }}>
                <img src={creatingQuotationSvg} style={{ width: '375px' }} />
                <h2 style={{ color: '#014672', fontWeight: 600, fontSize: '36px' }}>จัดทำใบเสนอราคา</h2>
                <div style={{ fontSize: '22px' }}>
                  หากต้องการสอบถามข้อมูลเกี่ยวกับใบเสนอราคา กรุณาติดต่อแอดมินที่ 02-821-6545 หรือ Line ID: @Q-CHANG installer
                </div>
              </div>
            </>
          )}
          {!contractorMode && (
            <EnButton
              className="btn-warning pull-right"
              disabled={publicDraftQuotation.length == 0}
              onClick={this.sendPublicDraftQuotationToCustomerEmail.bind(this, publicDraftQuotation)}
            >
              <i className="fa fa-paper-plane-o btn-icon" aria-hidden="true" />
              ส่งเมล์หาลูกค้า
            </EnButton>
          )}
          {enableNextStep && enableNextStep.length > 0 && !contractorMode && (
            <EnButton id="submit-button" className="btn-info pull-right" onClick={this.onSubmit.bind(this)}>
              <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />
              บันทึก
            </EnButton>
          )}
          <EnButton id="back-button" className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
            <i className="fa fa-reply btn-icon" aria-hidden="true" />
            กลับ
          </EnButton>
          {
            <a href={installationUrl} target="_blank">
              <EnButton disabled={!this.state.installationJobId} id="back-to-installation" className="btn-warning pull-left mr-auto">
                <i className="fa fa-external-link btn-icon" aria-hidden="true" />
                ดูงานติดตั้ง
              </EnButton>
            </a>
          }
        </div>
      </div>
    );
  }
}

export default inject('auth', 'job', 'quotation')(observer(EnStepSurveyQuotationManagement));
