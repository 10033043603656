import React from 'react';
import * as _ from 'lodash';
import { Modal } from 'react-bootstrap';
import SaleModelToggleButtonGroup from './SaleModelToggle';

export const PreviewPDF = (props) => {
  const renderPDF = (url) => {
    return <iframe src={url} width="100%" height="100%" />;
  };
  return (
    <Modal show={props.show} onHide={props.onClose} dialogClassName="preview-template-modal">
      <Modal.Header>
        <div style={{ cursor: 'pointer', fontSize: '24px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <SaleModelToggleButtonGroup formValues={props.formValues} handleChangFieldForm={props.handleChangFieldForm} />
          <span onClick={props.onClose}>x</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            height: 'calc(100vh - 232px)',
          }}
        >
          {props.formValues.sale_model === 'marketplace'
            ? renderPDF(props.pdf.marketplace)
            : props.formValues.sale_model === 'retail'
            ? renderPDF(props.pdf.retail)
            : null}
        </div>
      </Modal.Body>
    </Modal>
  );
};
