import React from 'react';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/Container';
import * as _ from 'lodash';
import WarrantyForm from './components/WarrantyForm';

export class WarrantyViewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Container isAdmin>
        <WarrantyForm readOnly={true} id={this.props.match.params.id} />
      </Container>
    );
  }
}

export default inject()(observer(WarrantyViewPage));
