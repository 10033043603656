import BaseStore from '../BaseStore';
import humps from 'humps';
import * as _ from 'lodash';
import config from '../../config';
import { http } from '../../utils/http';
import qs from 'querystring'
import AuthStore from '../AuthStore';

export class Product extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      items: [],
      info: this.initInfo(),
      productChannel: [],
    });
  }

  initInfo() {
    return {
      skuCode: '',
      name: '',
      description: {
        th: '',
      },
      shortDescription: {
        th: '',
      },
      shortName: {
        th: '',
      },
      descriptions: [],
      typeOfWork: '',
      images: [],
      price: 0,
      normalPrice: 0,
      calculator: {
        unitPrice: 0,
        unit: {
          th: '',
        },
        minQty: 1,
      },
      allowInstallment: false,
      installmentPeriod: 0,
      seo: {
        title: '',
        slug: '',
        keywords: '',
        description: '',
      },
      productType: 'S',
      isWageOnly: false,
      skuMapping: '',
      limitQty: false,
      guaranteeCode: '',
      isActive: true,
      lockDays: 3,
      sellingPoints: [this.initNewSellingPoint()],
      adBanner: null,
      bannerWithTimers: [this.initNewBannerWithTimer()],
      informations: [this.initNewInformation()],
      informationsWithTimer: [this.initNewInformationWithTimer()],
      requireWh: false,
      requireOnsitePicture: false,
      channel: '',
      contractorGrade: this.initcontractorGrade(),
      typeOfWorkProductMapping: []
    };
  }

  initcontractorGrade() {
    return {
      a: true,
      b: true,
      c: true,
      d: false,
      new: true,
    };
  }

  initNewBannerWithTimer() {
    return {
      dateUpload: undefined,
      dateDelete: undefined,
      banner: null,
    };
  }

  initNewDescription() {
    return {
      content: '',
      images: [],
    };
  }

  initNewSellingPoint() {
    return {
      icon: '',
      text: '',
    };
  }

  initNewInformation() {
    return {
      seqNo: '',
      title: '',
      content: '',
    };
  }

  initNewInformationWithTimer() {
    return {
      dateUpload: undefined,
      dateDelete: undefined,
      seqNo: '',
      title: '',
      content: '',
    };
  }

  addNewSellingPoint() {
    const sellingPoint = this.initNewSellingPoint();
    this.info.sellingPoints.push(sellingPoint);
  }

  addNewBannerWithTimer() {
    const bannerWithTimer = this.initNewBannerWithTimer();
    this.info.bannerWithTimers.push(bannerWithTimer);
  }

  addNewInformation() {
    const information = this.initNewInformation();
    this.info.informations.push(information);
  }

  addNewInformationWithTimer() {
    const informationWithTimer = this.initNewInformationWithTimer();
    this.info.informationsWithTimer.push(informationWithTimer);
  }

  deleteBannerWithTimer(index) {
    this.info.bannerWithTimers.splice(index, 1);
  }

  deleteSellingPoint(index) {
    this.info.sellingPoints.splice(index, 1);
  }

  deleteInformation(index) {
    this.info.informations.splice(index, 1);
  }

  deleteInformationWithTimer(index) {
    this.info.informationsWithTimer.splice(index, 1);
  }

  saveSellingPoint(index, key, value) {
    this.info.sellingPoints[index][key] = value;
  }

  saveBannerWithTimer(index, key, value) {
    this.info.bannerWithTimers[index][key] = value;
  }

  saveInformation(index, key, value) {
    this.info.informations[index][key] = value;
  }

  saveInformationWithTimer(index, key, value) {
    this.info.informationsWithTimer[index][key] = value;
  }

  saveContractorGrade(key, value) {
    this.info.contractorGrade[key] = value;
  }

  clearInfo() {
    this.info = this.initInfo();
  }

  saveInfo(key, value) {
    this.info[key] = value;
  }

  saveUnitPrice(value) {
    this.info.calculator.unitPrice = value;
  }

  saveUnit(value) {
    this.info.calculator.unit.th = value;
  }

  saveSeoInfo = (value, key) => {
    this.info.seo[key] = value;
  }

  saveProductType = (value) => {
    this.info.productType = value;
  }

  saveMinQty = (value) => {
    this.info.calculator.minQty = value;
  }

  clearItems() {
    this.items = [];
  }

  async getProductList(ids, groupIds, withCategory, field = undefined, channel='') {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      let url = ids ? `${config.api.qchang}/v1/products?id=${ids}&group_id=${groupIds}` : `${config.api.qchang}/v1/products`;
      if (ids && withCategory) {
        url = `${url}&withCategory=true`
      } else if (withCategory) {
        url = `${url}?withCategory=true`
      }
      if (field) {
        const channelParam = Array.isArray(channel) ? channel.join('&channel=') : channel;
        url = field && ( ids || withCategory ) ? url + `&field=${field}&channel=${channelParam}`: url + `?field=${field}`;
      }

      const response = await http.get(url);
      if (response.status === 200 && response.data) {
        this.items = humps.camelizeKeys(response.data.data);
        if (withCategory) {
          this.backboneCategory = response.data.backboneCategory;
        }
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getPaginatedProducts(query = { limit: 10, page: 1 }) {
    query = _.pickBy(query, (value) => !!value)
    const qstring = qs.stringify(_.pick(query, ['limit', 'page', 'search']))

    try {
      const response = await http.get(`${config.api.qchang}/v1/products/pagination?${qstring}`);
      if (response.status === 200 && response.data) {
        const items = humps.camelizeKeys(response.data.items)
        return Promise.resolve({
          ...response.data,
          items
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  async getProductById(id) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.qchang}/v1/products/${id}`);
      if (response.status !== 200 || !response.data || !response.data.data) {
        throw new Error('ไม่พบข้อมูล');
      }
      return humps.camelizeKeys(response.data.data);
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getProductForEdit(id) {
    try {
      const data = await this.getProductById(id);
      if (data) {
        const { sellingPoints, informations, informationsWithTimer, adBanner, bannerWithTimers, normalPrice, contractorGrade } = data;
        if (!Array.isArray(sellingPoints) || !sellingPoints.length) {
          data.sellingPoints = [this.initNewSellingPoint()];
        }
        if (!Array.isArray(informations) || !informations.length) {
          data.informations = [this.initNewInformation()];
        }
        if (!Array.isArray(informationsWithTimer) || !informationsWithTimer.length) {
          data.informationsWithTimer = [this.initNewInformationWithTimer()];
        }
        if (!adBanner) {
          data.adBanner = null;
        }
        if (!Array.isArray(bannerWithTimers) || !bannerWithTimers.length) {
          data.bannerWithTimers = [this.initNewBannerWithTimer()];
        }
        if (!contractorGrade) {
          data.contractorGrade = this.initcontractorGrade();
        }
        if (!normalPrice) {
          data.normalPrice = 0;
        }
        if (!data.isWageOnly) {
          data.isWageOnly = false;
        }
        if (!data.requireOnsitePicture) {
          data.requireOnsitePicture = false;
        }
        if (!data.typeOfWorkProductMapping) {
          data.typeOfWorkProductMapping = [];
        }
        if (!data.isSurveyDiscount) {
          data.isSurveyDiscount = false;
        }
      }
      this.info = data;
    } catch (error) {
      throw error;
    }
  }

  async createProduct() {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const info = this.toJS().info;
      if (info.channel && typeof info.channel === 'string') {
        info.channel = info.channel.split(',');
      }
      info.user = AuthStore.getUserEmail();
      const response = await http.post(`${config.api.qchang}/v1/products`, info);
      if (response.status !== 200) {
        if (response.data && response.data.message === 'DUPLICATED_SLUG') {
          throw new Error('Slug ซ้ำ');
        } else if (response.data && response.data.message === 'DUPLICATED_SKU_CODE') {
          throw new Error('รหัสสินค้าซ้ำ');
        }
        throw new Error('เพิ่มสินค้าไม่สำเร็จ');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateProduct(id) {
    this.info.informations.forEach((info, index) => {
      this.info.informations[index].seqNo = parseInt(info.seqNo, 10);
    });
    this.info.informations = _.orderBy(this.info.informations, 'seqNo');

    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const info = this.toJS().info;
      if (info.channel && typeof info.channel === 'string') {
        info.channel = info.channel.split(',');
      }
      info.user = AuthStore.getUserEmail();
      // console.log("UpdateProduct");
      // console.log('info ------------->>', info);
      // return
      const response = await http.put(`${config.api.qchang}/v1/products/${id}`, info);
      if (response.status !== 200) {
        if (response.data && response.data.message === 'DUPLICATED_SLUG') {
          throw new Error('Slug ซ้ำ');
        } else if (response.data && response.data.message === 'DUPLICATED_SKU_CODE') {
          throw new Error('รหัสสินค้าซ้ำ');
        }
        throw new Error('แก้ไขสินค้าไม่สำเร็จ');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async deleteProduct(id) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const response = await http.delete(`${config.api.qchang}/v1/products/${id}`);
      if (response.status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async uploadProductImage(file) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await http.postMultipartFormData(`${config.api.qchang}/v1/products/upload`, formData);
      if (response.status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }
      return response.data.data;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
  async updateDataProductFile(data) {
    this.isLoading = true
    try {
      const response = await http.post(`${config.api.sims}/qchang/v1/products/updateDataProductExcelFile`, data);
      if (response && response.data && response.data.data) {
        return response.data.data;
      }
      // this.isLoading = false
    } catch (error) {
      // this.isLoading = false
      throw new Error(error);
    } finally {
      this.isLoading = false;
    }
  }

  async getProductByChannel(channelName) {
    try {
      const response = await http.get(`${config.api.sims}/qchang/v1/products/getProductByChannel/${channelName}`);
      if (response.data) {
        this.productChannel = humps.camelizeKeys(response.data);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
}

export default new Product();
