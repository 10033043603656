import React from 'react';
import * as _ from 'lodash';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/Container';
import Loader from '../../../components/common/Loader';
import Notification from '../../../components/common/Notification';
import Pagination from '../../../components/common/Pagination';
import WarrantySearchForm from './components/WarrantySearchForm';
import WarrantyRowTable from './components/WarrantyRowTable';

import WarrantyHeaderTable from './components/WarrantyHeaderTable';
import swal from 'sweetalert2';
import WarrantyTemplateStore from '../../../stores/WarrantyTemplateStore';
import common, { defaultConfirmOption } from '../../../utils/common';
import infoError from '../../../assets/img/icn_error_2.svg';
import infoCheck from '../../../assets/img/icn_check.svg';
import { Modal } from 'react-bootstrap';

class WarrantyListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      warranty_templates: [],
      productCodes: [],
      expandList: [],
      showModal: false,
      errorWarranty: '',
      query: {},
      pagination: {
        current_page: 1,
        page_size: 10,
      },
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    await this.fetchData();
    this.setState({ isLoading: false });
  }

  handleExpand(id) {
    const { expandList } = this.state;
    if (_.includes(expandList, id)) {
      this.setState({ expandList: _.without(expandList, id) });
    } else {
      this.setState({ expandList: _.concat(expandList, id) });
    }
  }

  async fetchData(nextPage) {
    this.setState({ isLoading: true });
    try {
      const { page_size, current_page } = this.state.pagination;
      const { query } = { ...this.state };
      const pageNum = nextPage || current_page;
      let form = Object.assign({}, query);

      const response = await WarrantyTemplateStore.getAll({ page: pageNum, limit: page_size, ...form });
      const totalPage = Math.ceil(response.total / page_size);
      this.setState({
        warranty_templates: response.warranty_templates,
        pagination: {
          page_size: page_size,
          page: nextPage ? 1 : pageNum,
          current_page: pageNum,
          next_page: pageNum + 1,
          previous_page: pageNum - 1,
          has_next: pageNum < totalPage,
          has_previous: pageNum > 1,
          is_first_page: pageNum === 1,
          is_last_page: pageNum === totalPage,
          total_pages: totalPage,
          start_page_range: Math.floor((pageNum - 1) / 5) * 5 + 1,
          end_page_range: Math.min(Math.floor((pageNum - 1) / 5) * 5 + 5, totalPage),
          total_items: response.total,
        },
      });
    } catch (error) {
      console.log(error.message);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async handleDelete(id, code) {
    const result = await swal.fire({
      title: 'ยืนยันการลบข้อมูล',
      html: 'คุณต้องการลบข้อมูลหรือไม่?',
      imageWidth: 60,
      imageHeight: 60,
      width: 350,
      height: 300,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: { popup: 'swal2-border' },
      ...defaultConfirmOption({
        customClass: {
          title: 'primary-text-color',
        },
      }),
    });
    if (result.value) {
      this.setState({ isLoading: true });

      const response = await WarrantyTemplateStore.delete(id);
      this.setState({ isLoading: false });
      if (response.data && response.code !== 200 && response.data.errorCode) {
        if (Array.isArray(response.data.errorMessage)) {
          this.setState({
            errorWarranty: code,
            showModal: true,
            productCodes: response.data.errorMessage,
          });
        } else
          swal.fire({
            title: 'ไม่สามารถลบข้อมูลได้',
            html: response.data.errorMessage,
            confirmButtonText: 'ตกลง',
            ...defaultConfirmOption({ buttonWidth: '100%' }),
            imageUrl: infoError,
          });

        return null;
      }
      await this.fetchData();
      swal.fire({
        toast: true,
        title: 'ลบข้อมูลสำเร็จแล้ว',
        imageUrl: infoCheck,
        position: 'top',
        timer: 2000,
        customClass: 'swal2-success',
        showConfirmButton: false,
      });
    }
  }

  async handleChangeQuery(newQuery) {
    this.setState({ query: newQuery });
  }

  handleCopyToClipboard() {
    const content = this.state.productCodes.join(', ');
    navigator.clipboard
      .writeText(content)
      .then(() => {
        swal.fire({
          toast: true,
          title: 'คัดลอกสำเร็จ',
          imageUrl: infoCheck,
          position: 'top',
          timer: 2000,
          customClass: 'swal2-success',
          showConfirmButton: false,
        });
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  }

  checkFilterIsDirty() {
    const { query } = this.state;
    const result = Object.keys(query).some((key) => {
      if (key === 'announcement_date' || key === 'create_at') {
        return query[key] !== null;
      } else if (key === 'release_status' || key === 'is_published') {
        return query[key] !== '';
      }
      return query[key] !== '';
    });

    return result;
  }

  handleClearSearch() {
    this.setState({
      query: {
        name: '',
        recipientType: '',
        isPublish: '',
        isActive: '',
        publishedAt: null,
        createdAt: null,
      },
    });
    setTimeout(() => {
      this.fetchData();
    });
  }

  render() {
    const { warranty_templates, isLoading, expandList, productCodes, errorWarranty } = this.state;
    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={isLoading} />
          <Notification
            ref={(ref) => {
              this.noti = ref;
            }}
          />

          <Modal
            show={this.state.showModal}
            onHide={(e) => {
              this.setState({ showModal: false });
            }}
          >
            <Modal.Body>
              <div className="text-center">
                <img src={infoError} height={42} />
                <h4 style={{ color: '#014672' }}>ไม่สามารถลบข้อมูลได้</h4>
                <div style={{ color: '#757575' }}>
                  เนื่องจากใบรับประกัน <span style={{ color: '#014672', textDecoration: 'underline' }}>{errorWarranty}</span> มีการใช้งาน Product code
                  ดังนี้
                </div>
              </div>
              <div
                style={{
                  backgroundColor: '#F8F8F8',
                  borderRadius: '8px',
                  margin: '12px 0px',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 5,
                  overflow: 'auto',
                  textOverflow: 'ellipsis',
                  padding: '0 4px',
                }}
              >
                {productCodes.join(', ')}
              </div>

              <div>
                <small style={{ color: '#757575' }}>ทั้งหมด {productCodes.length} รายการ</small>
                <button
                  class="btn btn-sm"
                  style={{
                    fontSize: '14px',
                    color: 'var(--text-info-color)',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    border: '1px solid var(--text-info-color)',
                    borderRadius: '99px',
                  }}
                  onClick={this.handleCopyToClipboard.bind(this)}
                >
                  คัดลอก
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div style={{ padding: '0px 16px', paddingBottom: '20px' }}>
                <button
                  className="btn"
                  style={{ width: '100%', backgroundColor: 'var(--text-info-color)', color: 'white' }}
                  onClick={(e) => this.setState({ showModal: false })}
                >
                  ตกลง
                </button>
              </div>
            </Modal.Footer>
          </Modal>
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">ใบรับประกัน</h4>
              </div>
              <div className="card-content">
                <WarrantySearchForm
                  query={this.state.query}
                  handleChangeQuery={(key, values) => this.handleChangeQuery(key, values)}
                  handleClickSearch={this.fetchData.bind(this)}
                  handleClearSearch={this.handleClearSearch.bind(this)}
                />
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <WarrantyHeaderTable history={this.props.history} />
                        <tbody>
                          {_.isEmpty(warranty_templates) && this.checkFilterIsDirty() ? (
                            <tr key={0}>
                              <td colSpan="12" style={{ height: '270px' }}>
                                <div className=" d-flex w-100 justify-content-center justify-items-center" style={{ height: '100%' }}>
                                  <div className="text-center">
                                    <img className="additional-products-page__empty-img" src={require('../../../assets/img/empty-search.svg')} />
                                    <div style={{ fontSize: '24px', marginTop: '16px', color: '#014672' }}>ไม่พบข้อมูลที่ค้นหา</div>
                                    <div style={{ color: '#838799' }}>กรุณาลองใหม่อีกครั้ง</div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ) : (
                            _.isEmpty(warranty_templates) && (
                              <tr key={0}>
                                <td colSpan="12" style={{ height: '270px' }}>
                                  <div className=" d-flex w-100 justify-content-center justify-items-center" style={{ height: '100%' }}>
                                    <div className="text-center">
                                      <img className="additional-products-page__empty-img" src={require('../../../assets/img/empty-box.svg')} />
                                      <div style={{ marginTop: '16px', color: '#838799' }}>ยังไม่มีข้อมูลใบรับประกัน</div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                          {warranty_templates &&
                            warranty_templates.map((warranty) => (
                              <React.Fragment key={warranty._id}>
                                <WarrantyRowTable
                                  isParent={true}
                                  hasSubWarranty={warranty.sub_warranty_templates && warranty.sub_warranty_templates.length > 0}
                                  isExpand={expandList.includes(warranty._id)}
                                  warranty={warranty}
                                  fetchData={this.fetchData.bind(this)}
                                  history={this.props.history}
                                  onDelete={(id) => this.handleDelete(id, warranty.code)}
                                  onExpand={(id) => this.handleExpand(id)}
                                />
                                {expandList.includes(warranty._id) &&
                                  warranty.sub_warranty_templates &&
                                  warranty.sub_warranty_templates.map((sub_warranty) => (
                                    <WarrantyRowTable
                                      warranty={sub_warranty}
                                      history={this.props.history}
                                      fetchData={this.fetchData.bind(this)}
                                      onDelete={(id) => this.handleDelete(id, warranty.code)}
                                    />
                                  ))}
                              </React.Fragment>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {this.state.pagination && this.state.pagination.total_items ? (
                  <div className="row">
                    <div className="col-md-12">
                      <Pagination pagination={this.state.pagination} handlePageClick={this.fetchData.bind(this)} />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('misc', 'typeofwork')(observer(WarrantyListPage));
