// import * as _ from 'lodash';
import BaseStore from './BaseStore';
import config from '../config';
// import datetime from '../utils/datetime';
import http from '../utils/http';
// import address from '../utils/address';
import common from '../utils/common';

export class GuaranteeStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      isSubmitting: false,
      guaranteeData: [],
      info: {
        name: undefined,
        code: undefined,
        totalDate: undefined,
        guaranteeInfo: [],
        notGuaranteeInfo: [],
      },
    });
  }

  clearInfo() {
    this.guaranteeData = [];
    this.info = {
      name: undefined,
      code: undefined,
      totalDate: undefined,
      guaranteeInfo: [],
      notGuaranteeInfo: [],
    };
  }

  mapDataToObject(data) {
    if (data && data.value) {
      const { name, code, total_date, guarantee_info, not_guarantee_info } = data.value;
      this.info.name = name;
      this.info.code = code;
      this.info.totalDate = total_date;
      let response = {};
      if (guarantee_info) {
        response.guaranteeInfo = guarantee_info;
      }
      if (not_guarantee_info) {
        response.notGuaranteeInfo = not_guarantee_info;
      }
      return { ...data.value, ...response };
    }
  }

  saveInfo(key, value) {
    this.info[key] = value;
  }

  async getAllGuarantee() {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const response = await http.get(`${config.api.sims}/v1/guarantees`);
      if (response && response.data && response.data.data) {
        this.guaranteeData = response.data.data[0].value;
        // return response.data.data;
      }
      if (response.status !== 200) {
        throw new Error('โหลดข้อมูลล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getGuaranteeByFilter(query) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const response = await http.get(`${config.api.sims}/v1/guarantees/filter`, {
        params: {
          saleOrderCode: query.saleOrderCode
        }
      });
      if (response && response.data && response.data.data) {
        return this.mapDataToObject(response.data.data[0]);
      }
      if (response.status !== 200) {
        return null;
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getGuaranteeByCode(code) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const response = await http.get(`${config.api.sims}/v1/guarantees/${code}`);
      if (response && response.data && response.data.data) {
        return this.mapDataToObject(response.data.data[0]);
      }
      if (response.status !== 200) {
        throw new Error('โหลดข้อมูลล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async createGuarantee(options, previewPdf = false) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const info = this.toJS().info;
      info['guaranteeInfo'] = options.guaranteeInfo;
      info['notGuaranteeInfo'] = options.notGuaranteeInfo;

      const originalUrl = `${config.api.sims}/v1/guarantees`;
      const qs = `?preview=true`;
      const url = previewPdf ? originalUrl + qs : originalUrl;

      const { data, status } = await http.post(url, info);
      if (status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }

      if (previewPdf) {
        common.openPdfInNewTab(data)
      }

      return { message: 'success' };
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * Updates the guarantee information and optionally opens the generated PDF in new tab.
   */
  async updateGuarantee(options, previewPdf = false) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const info = this.toJS().info;
      info['guaranteeInfo'] = options.guaranteeInfo;
      info['notGuaranteeInfo'] = options.notGuaranteeInfo;
      if (!info.code) {
        throw new Error('การอัพเดตล้มเหลว');
      }
      const originalUrl = `${config.api.sims}/v1/guarantees`;
      const qs = `?preview=true`;
      const url = previewPdf ? originalUrl + qs : originalUrl;
      const { data, status } = await http.put(url, info);
      if (status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }
      if (previewPdf) {
        common.openPdfInNewTab(data)
      }
      // return { message: 'success' };
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getWarrantyOptions(params) {
    try {
      
      const res = await http.get(`${config.api.sims}/qchangv2/v1/warranty-templates?${params}`);
      if (res.status === 200 && res.data) {
        return res.data;
      }
      return undefined;

    } catch (error) {
      throw error
    }
  }
}

export default new GuaranteeStore();
