import React, { Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { NavLink, withRouter } from 'react-router-dom';
import config from '../config';
import iconSurvey from '../assets/img/icon สำรวจ.svg';
import { isAllow, isAllowPermission } from '../utils/permission';
import fileVersion from '../config/version.json';

export class SideMenu extends React.Component {
  onSignOut() {
    this.props.auth.signOut();
  }

  toggleMenu() {
    let doc = document.getElementsByClassName('perfect-scrollbar-on');
    if (doc && doc.length) {
      let hDoc = doc[0];
      hDoc.classList.toggle('nav-open', false);
    }
  }

  async onClickDownload() {
    const userInfo = this.props.auth.getUserInfo();
    // const channel = userInfo.channel ? userInfo.channel.channelName || userInfo.channel.channel_name: '';
    const channel = this.props.auth.getChannelName() || '';
    const storeId = this.props.auth.getStoreId();

    // await this.props.exportdata.exportJobs(this.props.auth.getStoreId(), null, channel);
    this.props.history.push(`${config.publicUrl}/superadmin/download/jobbychannel/${storeId}/${channel}`);
  }


  onClickSummaryPayment() {
    this.props.history.push(`${config.publicUrl}/paymentSummary`);
  }

  render() {
    const isBuAccount = this.props.auth.isBuAccount();
    const isSuperAdmin = this.props.auth.isSuperAdmin();
    const isAdminBU = this.props.auth.isAdminBU();
    let isSCGHome = this.props.auth.isChannelSCGHome();
    const isAdmin = !isSuperAdmin && !isAdminBU && !isBuAccount;
    const store = this.props.auth.getStore();
    const userInfo = this.props.auth.getUserInfo();

    if (isBuAccount) {
      isSCGHome = false
    }
    return (
      <div className="sidebar-wrapper">
        <ul className="nav">
          {isAllow("VIEW_OVERALL_SERVICE_DATE_INFORMATION") && (isSCGHome || isAdmin) && (
            <li>
              <NavLink
                to={`${config.publicUrl}/admin/calendar`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-calendar" aria-hidden="true" />
                <p id="calendar">ปฏิทิน</p>
              </NavLink>
            </li>
          )}
          {isBuAccount && (
            <li>
              <NavLink
                to={`${config.publicUrl}/buaccount/jobTracking`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="" aria-hidden="true" >
                  <img alt="" src={iconSurvey} style={{ paddingLeft: '4px' }} />
                </i>
                <p id="calendar">ติดตามสถานะงาน</p>
              </NavLink>
            </li>
          )}
          {(isSCGHome || isAdmin) && (
            <li>
              <NavLink
                to={`${config.publicUrl}/admin/store/area`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-map-o" aria-hidden="true" />
                <p id="store-area">พื้นที่ขาย</p>
              </NavLink>
            </li>
          )}
          {isAllowPermission((isSCGHome || isAdmin) && (!isBuAccount), 'VIEW_AND_EDIT_CONTRACTOR_TEAM') && (
            <li>
              <NavLink
                to={`${config.publicUrl}/admin/store/contractors`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-users" aria-hidden="true" />
                <p id="select-contractor">เลือกทีมช่าง</p>
              </NavLink>
            </li>
          )}
          {isAllowPermission(((isSCGHome || isAdmin) && store && store.po_require), 'VIEW_LIST_OF_CONTRACTOR') && (
            <li>
              <NavLink
                to={`${config.publicUrl}/admin/contractor/cost`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-usd" aria-hidden="true" />
                <p id="select-contractor">ตั้งค่าการออกเอกสาร</p>
              </NavLink>
            </li>
          )}
          {isAllowPermission((isSCGHome || isAdmin), 'VIEW_AND_DOWNLOAD_LIST_OF_Q_CHANG_FILES') && (
            <li>
              <NavLink
                to={`${config.publicUrl}/superadmin/download/jobbychannel/${this.props.auth.getStoreId()}/${this.props.auth.getChannelName() || ''}`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-download" aria-hidden="true" />
                <p id="download-job">ดาวน์โหลดคิวช่าง</p>
              </NavLink>
            </li>
          )}
          {isAllowPermission((isAdmin), 'VIEW_AND_DOWNLOAD_LIST_OF_Q_CHANG_FILES') && (
            <li>
              <NavLink
                to={`${config.publicUrl}/admin/download/promotions`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-download" aria-hidden="true" />
                <p id="download-promotions">ดาวน์โหลดโปรโมชั่น</p>
              </NavLink>
            </li>
          )}
          {(isBuAccount) && (
            <li>
              <a
                className="c-pointer"
                activeClassName="active"
                onClick={this.onClickDownload.bind(this)}
              >
                <i className="fa fa-download" aria-hidden="true" />
                <p id="download-job">ดาวน์โหลดรายงาน</p>
              </a>
            </li>
          )}
          {(isBuAccount && store.request_store_payment) && (
            <li>
              <a
                className="c-pointer"
                activeClassName="active"
                onClick={this.onClickSummaryPayment.bind(this)}
              >
                <i className="fa fa-tasks" aria-hidden="true" />
                <p id="download-job">สรุปการชำระเงินทั้งหมด</p>
              </a>
            </li>
          )}
          {isAllowPermission((isSCGHome || isAdmin), 'SEARCH_AND_VIEW_A_LIST_OF_JOBS_AND_DETAILS') && (
            <li>
              <NavLink
                to={`${config.publicUrl}/admin/jobs/list`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active" href=""
              >
                <i className="fa fa-tasks" aria-hidden="true" />
                <p id="job-list">ค้นหางาน</p>
              </NavLink>
            </li>
          )}

          {isAdminBU && (
            <li>
              <NavLink
                to={`${config.publicUrl}/admin/jobs/export`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-download" aria-hidden="true" />
                <p id="download-job">ดาวน์โหลดงาน</p>
              </NavLink>
            </li>
          )}
          {/* <li>
            <NavLink to={`${config.publicUrl}/calendar/admin/contractorteam`}>
              <i className="fa fa-wrench" aria-hidden="true" />
              <p>เลือกทีมช่าง</p>
            </NavLink>
          </li> */}
          {isAllowPermission(isSuperAdmin, "VIEW_OVERALL_SERVICE_INFORMATION") && (
            <li>
              <NavLink
                to={`${config.publicUrl}/superadmin/jobs`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-search" aria-hidden="true" />
                <p id="search-job">ค้นหางาน</p>
              </NavLink>
            </li>
          )}
          {isAllowPermission((isSuperAdmin || isAdmin), "SEARCH_AND_VIEW_MEMBER_INFORMATION") && (
            <li>
              <NavLink
                to={`${config.publicUrl}/superadmin/userInfo`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-user" aria-hidden="true" />
                <p id="search-job">จัดการสมาชิก</p>
              </NavLink>
            </li>
          )}
          {isSuperAdmin && (
            <li>
              <NavLink
                to={`${config.publicUrl}/superadmin/store`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-home" aria-hidden="true" />
                <p id="edit-store">ร้านค้า</p>
              </NavLink>
            </li>
          )}
          {isAllowPermission(isSuperAdmin, "SEARCH_AND_VIEW_A_LIST_OF_CONTRACTORS") && (
            <li>
              <NavLink
                to={`${config.publicUrl}/superadmin/approve`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-users" aria-hidden="true" />
                <p id="approve-contractor">ช่าง</p>
              </NavLink>
            </li>
          )}
          {isAllowPermission(isSuperAdmin, isAdmin) && (
            <li>
              <NavLink
                to={`${config.publicUrl}/superadmin/sub-contractors`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-users" aria-hidden="true" />
                <p id="approve-contractor">จัดการทีมช่าง</p>
              </NavLink>
            </li>
          )}
          {isSuperAdmin && (
            <li>
              <NavLink
                to={`${config.publicUrl}/superadmin/training`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-file" aria-hidden="true" />
                <p id="training">คอร์สอบรม</p>
              </NavLink>
            </li>
          )}
          {isAllowPermission((isSCGHome || isSuperAdmin), "VIEW_A_LIST_OF_WORK_TYPE") && (
            <li>
              <NavLink
                to={`${config.publicUrl}/superadmin/typeofwork`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-briefcase" aria-hidden="true" />
                <p id="type-of-work">ประเภทงาน</p>
              </NavLink>
            </li>
          )}
          {isAllowPermission((isSCGHome || isSuperAdmin), 'VIEW_A_LIST_OF_SKU') && (
            <li>
              <NavLink
                to={`${config.publicUrl}/superadmin/sku`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-product-hunt" aria-hidden="true" />
                <p id="sku">SKU</p>
              </NavLink>
            </li>
          )}
          {isSuperAdmin && (
            <li>
              <NavLink
                to={`${config.publicUrl}/qchang/solution`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-lightbulb-o" aria-hidden="true" />
                <p id="qchang-solution">โซลูชั่น (คิวช่าง)</p>
              </NavLink>
            </li>
          )}
          {isSuperAdmin && (
            <li>
              {isAllow("VIEW_A_LIST_OF_PRODUCT_GROUP") && (
                <NavLink
                  to={`${config.publicUrl}/qchang/productgroup`}
                  onClick={this.toggleMenu.bind(this)}
                  activeClassName="active"
                >
                  <i className="fa fa-cubes" aria-hidden="true" />
                  <p id="qchang-product">กลุ่มสินค้า (คิวช่าง)</p>
                </NavLink>
              )}
            </li>
          )}
          {isAllowPermission((isSCGHome || isSuperAdmin), 'VIEW_A_LIST_OF_PRODUCT') && (
            <li>
              <NavLink
                to={`${config.publicUrl}/qchang/product`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-cube" aria-hidden="true" />
                <p id="qchang-product">สินค้า (คิวช่าง)</p>
              </NavLink>
            </li>
          )}
          {isSuperAdmin && (
            <li>
              <NavLink
                to={`${config.publicUrl}/superadmin/channel`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active">
                <i className="fa fa-handshake-o" aria-hidden="true" />
                <p id="channel">ช่องทางการขาย</p>
              </NavLink>
            </li>
          )}
          {isSuperAdmin && (
            <Fragment>
              <li>
                <NavLink
                  to={`${config.publicUrl}/qchang/coupongroup`}
                  onClick={this.toggleMenu.bind(this)}
                  activeClassName="active"
                >
                  <i className="fa fa-tags" aria-hidden="true" />
                  <p id="qchang-coupon">กลุ่มรหัสส่วนลด (คิวช่าง)</p>
                </NavLink>
              </li>
              <li>
                {isAllow("SEARCH_AND_VIEW_A_LIST_OF_COUPON") &&
                  <NavLink
                    to={`${config.publicUrl}/qchang/coupon`}
                    onClick={this.toggleMenu.bind(this)}
                    activeClassName="active"
                  >
                    <i className="fa fa-tag" aria-hidden="true" />
                    <p id="qchang-coupon">รหัสส่วนลด (คิวช่าง)</p>
                  </NavLink>
                }
              </li>
              <li>
                <NavLink
                  to={`${config.publicUrl}/qchang/set-banner`}
                  onClick={this.toggleMenu.bind(this)}
                  activeClassName="active"
                >
                  <i className="fa fa-tag" aria-hidden="true" />
                  <p id="qchang-set-banner">Set banner</p>
                </NavLink>
              </li>
            </Fragment>
          )}
          {isSuperAdmin && config.showMenu && config.showMenu.qocBroadcast && (
            <li>
              <NavLink to={`${config.publicUrl}/superadmin/qoc-broadcasts`} onClick={this.toggleMenu.bind(this)} activeClassName="active">
                <i className="fa fa-bullhorn" aria-hidden="true" />
                <p id="job-list">ประกาศ QOC</p>
              </NavLink>
            </li>
          )}
          {isSuperAdmin && (
            <li>
              {isAllow('SEARCH_AND_VIEW_BROADCAST_LIST') && (
                <NavLink to={`${config.publicUrl}/superadmin/broadcasts`} onClick={this.toggleMenu.bind(this)} activeClassName="active">
                  <i className="fa fa-podcast" aria-hidden="true" />
                  <p id="job-list">ประกาศ App Buddy</p>
                </NavLink>
              )}
            </li>
          )}
          {isAllowPermission(isSuperAdmin, 'VIEW_A_LIST_OF_GUARANTEE') && (
            <li>
              <NavLink
                to={`${config.publicUrl}/qchang/warranty`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-file-text-o" aria-hidden="true" />
                <p id="qchang-coupon">ใบรับประกัน</p>
              </NavLink>
            </li>
          )}
          {isAllowPermission(isSuperAdmin, "EDIT_PROJECTS_PORTFOLIO_CONFIGURATION") && (
            <li>
              <NavLink
                to={`${config.publicUrl}/qchang/project/config`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-gear" aria-hidden="true" />
                <p id="qchang-project-config">ตั้งค่าผลงาน</p>
              </NavLink>
            </li>
          )}
          {
            !isBuAccount && (
              <li >
                <NavLink
                  to={`${config.publicUrl}/employee`}
                  onClick={this.toggleMenu.bind(this)}
                  activeClassName="active"
                >
                  <i className="fa fa-users" aria-hidden="true" />
                  <p id="employees">พนักงาน</p>
                </NavLink>
              </li>
            )
          }
          {isSuperAdmin && (
            <li>
              <NavLink
                to={`${config.publicUrl}/superadmin/export`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-download" aria-hidden="true" />
                <p id="qchang-coupon">ดาวน์โหลด</p>
              </NavLink>
            </li>
          )}
          {isAllowPermission(isSuperAdmin, isAllow('SEARCH_AND_VIEW_A_LIST_OF_CONTACTORS')) && (
            <li>
              <NavLink
                to={`${config.publicUrl}/admin/contractors/list`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-tasks" aria-hidden="true" />
                <p id="job-list">รายการช่าง</p>
              </NavLink>
            </li>
          )}
          {(isAdmin || isSuperAdmin) && (
            <li>
              <NavLink
                to={`${config.publicUrl}/admin/additional-products`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-list" aria-hidden="true" />
                <p id="additional-products">รายการสินค้าส่วนเพิ่ม</p>
              </NavLink>
            </li>
          )}
          {/* {isSuperAdmin && (
            <li>
              <NavLink
                to={`${config.publicUrl}/superadmin/ordersummary`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-list-ol" aria-hidden="true" />
                <p id="job-list">สรุปรายการจ่ายเงิน</p>
              </NavLink>
            </li>
          )} */}

          {isSuperAdmin && (
            <li>
              <NavLink
                to={`${config.publicUrl}/superadmin/ordersummarySCB`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-list-ol" aria-hidden="true" />
                <p id="job-list">สรุปรายการจ่ายเงิน SCB</p>
              </NavLink>
            </li>
          )}
          {isSuperAdmin && (
            <li>
              <NavLink
                to={`${config.publicUrl}/superadmin/setupCalendar`}
                onClick={this.toggleMenu.bind(this)}
                activeClassName="active"
              >
                <i className="fa fa-calendar" aria-hidden="true" />
                <p id="job-list">ตั้งค่าปฏิทิน</p>
              </NavLink>
            </li>
          )}
          {/* {
            isSuperAdmin &&
            <li>
              <NavLink to={`${config.publicUrl}/qchang/review/add`} onClick={this.toggleMenu.bind(this)} activeClassName="active">
                <i className="fa fa-star" aria-hidden="true" />
                <p id="qchang-product">รีิวิว</p>
              </NavLink>
            </li>
          } */}
          <li>
            <a className="c-pointer" onClick={this.onSignOut.bind(this)}>
              <i className="fa fa-sign-out" aria-hidden="true" />
              <p id="logout">ออกจากระบบ</p>
            </a>
          </li>
          <li>
            <a className="btn btn-light" >
              <p>api version: {userInfo.apiVersion}</p>
              <p>web version: {fileVersion.version}</p>
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default withRouter(inject('auth', 'exportdata')(observer(SideMenu)));
