import React from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

const getButtonStyle = (currentValue, targetValue) => {
  const defaultStyle = {
    border: '0.5px solid #A1B4DE',
    textTransform: 'none',
    fontSize: '16px',
  };
  return currentValue === targetValue ? { ...defaultStyle, backgroundColor: '#E3F2FF' } : defaultStyle;
};

const SaleModelToggleButtonGroup = ({ formValues, handleChangFieldForm }) => (
  <ToggleButtonGroup type="radio" name="sale_model" value={formValues['sale_model']}>
    {['marketplace', 'retail'].map((value) => (
      <ToggleButton
        key={value}
        value={value}
        onClick={() => handleChangFieldForm('sale_model', value)}
        style={getButtonStyle(formValues.sale_model, value)}
      >
        {value.charAt(0).toUpperCase() + value.slice(1)}
      </ToggleButton>
    ))}
  </ToggleButtonGroup>
);

export default SaleModelToggleButtonGroup;
