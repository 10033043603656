import React from 'react';
import EnButton from '../../../../components/form/EnButton';
import moment from 'moment';
import EnTextFormGroup from '../../../../components/form/EnTextFormGroup';
import swal from 'sweetalert2';
import { checkEmoji, FormValidation, minLength, required } from '../../../../components/form/FormValidation';

class WarrantySearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        name: '',
        code: '',
      },
    };
  }

  isFormValid() {
    const { query } = this.state;
    const requiredFields = [minLength(3)(query.name), minLength(3)(query.code)];

    return !requiredFields.some((field) => field);
  }

  async handleChange(key, event) {
    const newQuery = { ...this.state.query };

    if (event && event.target) {
      return this.setState({ query: { ...newQuery, [key]: event.target.value } });
    }
  }

  async onClearSearch() {
    this.setState({
      query: {
        name: '',
        code: '',
      },
    });
    this.props.handleClearSearch();
  }

  checkInstDayBlock(date) {
    const currentDate = moment();
    const threeMonthsAgo = currentDate.clone().subtract(3, 'months').startOf('month');
    const threeMonthsFromNow = currentDate.clone().add(3, 'months').endOf('month');

    return moment(date).isBefore(threeMonthsAgo) || moment(date).isAfter(threeMonthsFromNow);
  }

  onSubmit() {
    const { query } = this.state;
    const { handleClickSearch, handleChangeQuery } = this.props;
    this.frm.isValid();
    const isFormValid = this.isFormValid();

    if (!isFormValid) {
      return;
    }

    handleChangeQuery(query);
    setTimeout(() => {
      handleClickSearch();
    });
  }

  render() {
    const { query } = this.state;
    return (
      <div>
        <FormValidation
          ref={(el) => {
            this.frm = el;
          }}
        >
          <div className="row" style={{ margin: '1rem' }}>
            {/* column1 */}
            <div className="col-md-6">
              <div className="row">
                <label className="col-md-4 control-label">รหัส</label>
                <div className="col-md-8">
                  <EnTextFormGroup
                    type="text"
                    placeholder="ค้นหาด้วยรหัส"
                    value={query['code']}
                    onChange={(value) => this.handleChange('code', value)}
                    notRequired
                    validations={[minLength(3)]}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <label className="col-md-4 control-label">ชื่อใบรับประกัน</label>
                <div className="col-md-8">
                  <EnTextFormGroup
                    type="text"
                    placeholder="ค้นหาด้วยชื่อใบรับประกัน"
                    value={query['name']}
                    onChange={(value) => this.handleChange('name', value)}
                    notRequired
                    validations={[minLength(3)]}
                  />
                </div>
              </div>
            </div>
          </div>
        </FormValidation>
        <div className="row" style={{ paddingRight: '1rem' }}>
          <EnButton className="btn-warning pull-right" onClick={this.onClearSearch.bind(this)}>
            <i className="fa fa-refresh btn-icon" aria-hidden="true" />
            ล้างตัวกรอง
          </EnButton>
          <EnButton className="btn-info pull-right" onClick={this.onSubmit.bind(this)}>
            <i className="fa fa-search btn-icon" aria-hidden="true" />
            ค้นหา
          </EnButton>
        </div>
      </div>
    );
  }
}

export default WarrantySearchForm;
