import React, { Component } from 'react';
import EnButton from "./EnButton";
import Swal from 'sweetalert2';
import { observer, inject } from 'mobx-react';
import { get } from 'lodash';

class UploadDoc extends React.Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
    }

    onAddFile = async(files, name) => {
        if (!files || !files.length) return;

        try {
            // 10 mb limit
            const limitSize = 10 * 1024 * 1024;
            let isOverLimit = 0;
            for (const file of files) {
                isOverLimit += file.size;
            }

            if (isOverLimit > limitSize) throw new Error('ไฟล์ทั้งหมดมีขนาดใหญ่เกิน 10 MB');

            await this.props.job.uploadPartnerWarranty(files, name);
            window.location.reload();
        } catch (error) {
            await Swal.fire({
                icon: 'error',
                title: get(error, 'message', 'อัพโหลดไฟล์ล้มเหลว!'),
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: false,
            });
        }
    }

    render() {
        const { name, ...props } = this.props;

        return (
            <div>
                <label>
                    <input
                        name={name}
                        type='file'
                        ref={this.fileInputRef}
                        multiple
                        accept="image/*,image/x-raw,image/heic,.pdf"
                        style={{ display: 'none' }}
                        onChange={(event) => this.onAddFile(event.target.files, name) }
                    />
                </label>
                <EnButton
                    className='btn-primary'
                    onClick={() => this.fileInputRef.current.click()}
                    {...props}
                >
                    เพิ่มเอกสาร
                </EnButton>
            </div>
        );
    };
}

export default inject('job')(observer(UploadDoc));