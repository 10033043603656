import storage from "store";

export const ROLE = {
  CAMPAIGN_MANAGER: 'Campaign Manager',
  INSTALLER_MANAGEMENT: 'Installer Management',
  INSTALLER_SUPER_ADMIN: 'Installer Super Admin',
};

const userInfo = () => {
  const currentRole = (storage.get("INFO") || {}).currentRole;
  const permissions = storage.get("PERM") || [];
  return { currentRole, permissions }
}

export const isAllow = (permission) => {
  const { currentRole, permissions } = userInfo();
  return !currentRole || permissions.includes(permission);
};

export const isAllowPermission = (isOldPermission, permission) => {
  const { currentRole } = userInfo();
  return isOldPermission || (currentRole && isAllow(permission));
};

export const isRole = (role) => {
  const { currentRole } = userInfo();
  return currentRole && currentRole.name === role;
};

export const isCampaignManager = () => {
  const { currentRole } = userInfo();
  return currentRole && currentRole.name === ROLE.CAMPAIGN_MANAGER;
}