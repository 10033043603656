import React from 'react';
import * as _ from 'lodash';
import { observer, inject } from 'mobx-react';
import EnTextAreaFormGroup from '../../../components/form/EnTextAreaFormGroup';
import FormGroup from '../../../components/form/FormGroup';
import EnButton from '../../../components/form/EnButton';
import * as stCommon from './stepCommon';
import EnTabContent from '../../../components/form/EnTabContent';
import { EnTextLabel } from '../../../components/form/EnTextLabel';
import swal from 'sweetalert2';
import Modal from 'react-responsive-modal';
import { isAllow } from '../../../utils/permission';
import config from '../../../config';
import authStore from '../../../stores/AuthStore';
import history from '../../../utils/history';
import {
  TypeOfJobs,
  ProductList,
  AvailableTime,
  MarketPlaceHideButton,
  MarketPlaceCreditHideButton,
  RetailHideButton,
} from '../../../utils/data';
export class EnInstalledStart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNextStep: false,
      currentStep: 'installation_started',
      nextStep: 'installation_finished',
      remark: 'installationAfterRemark',
      files: 'installationAfterFiles',
      chargeFile: 'additionalCharge',
      remarkTitle: 'หมายเหตุหลังติดตั้ง',
      addedFiles: [],
      addedOnsiteFiles: [],
      openModalOnsite: false,
      openModalReject: false,
      openModalBefore: false,
      openModalStart: false,
      showChargeDropzone: false,
      orderId: null,
      trackingUrl: ''
    };
  }

  async componentDidMount() {
    const jobStore = this.props.job.toJS();
    const jobInfo = jobStore.info;
    const jobId = jobInfo.id;
    if (jobInfo && jobInfo.storeId && jobInfo.refCode) {
      if (
        jobInfo.storeId === config.qchang.storeId ||
        (this.props.auth.isSuperAdmin() && jobInfo.storeId === config.qchang.storeId)
      ) {
        const orderInfo = await this.props.job.getOrderTracking(
          jobInfo.refCode
        );
        if (!_.isNil(orderInfo) && !_.isEmpty(orderInfo)) {
          this.setState({ orderId: orderInfo._id, orderInfo });
        }
      }
    }

    let trackingUrl = `${config.qchang.consumerWeb}/tracking/order/${this.state.orderId}?jobId=${jobInfo.id}`;
    if (jobInfo && jobInfo.jobType === 'S') {
      const jobIns = await this.props.job.getInstallationJobInfo(
        jobInfo.jobCode,
        false
      );
      if (jobIns) {
        trackingUrl = `${config.qchang.consumerWeb}/tracking/order/${this.state.orderId}?jobId=${jobIns._id}`;
      }
    }
    this.setState({ trackingUrl: trackingUrl });

    this.getMapFilesCustomer(jobId);
  }

  async onUploadAndNext(nestStatus) {
    try {
      this.setState({
        isNextStep: true,
      });
      stCommon.uploadAndNextStep(this.dropzone, nestStatus, this.props.contractorMode, true);
    } catch (error) {
      stCommon.onError(error.message);
    }
  }

  async onClickNextState(jobType, status, installationAfterFiles) {
    const jobStore = this.props.job;

    if (jobType === 'I' && status === 'installation_started') {
      if (_.isEmpty(installationAfterFiles) && _.isEmpty(this.state.addedFiles)) {
        console.log('installation file is required at least 1');
        await swal.fire({
          title: 'กรุณาอัพโหลดรูปภาพอย่างน้อย 1 รูป',
          icon: 'error',
        });
        return;
      }
    }

    await this.onUploadAndNext(this.state.nextStep);
    // await jobStore.createGuaranteeJob();
  }

  onSubmit() {
    try {
      if (this.dropzone && this.dropzone.getFiles() && this.dropzone.getFiles().length) {
        stCommon.doUploadFiles(this.dropzone);
      } else {
        stCommon.onUploadComplete([], this.state, this.props.contractorMode, this.props.onSubmit);
      }
    } catch (error) {
      stCommon.onError(error.message);
    }
  }

  onOpenShowOnsite() {
    try {
      this.setState({ openModalOnsite: true });
    } catch (error) {
      // onError(error.message);
    }
  }

  onOpenShowReject() {
    try {
      this.setState({ openModalReject: true });
    } catch (error) {
      // onError(error.message);
    }
  }

  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  onAddedInstallationFile() {
    if (this.dropzone) {
      this.setState({
        addedFiles: this.dropzone.getFiles(),
      });
    }
  }

  onAddedOnsiteFile() {
    if (this.dropzone) {
      this.setState({
        addedOnsiteFiles: this.dropzone.getFiles(),
      });
    }
  }

  getRejectReason(data) {
    const date = stCommon.formatDate(data['date']);
    const title = 'เจ้าของบ้านต้องการให้แก้ไขงานครั้งล่าสุด';
    const span = 'เนื่องจาก ' + data['reject_reason'];
    return stCommon.showStepStatus('fa fa-times-circle-o', title, date, 'rgb(165, 64, 61)', span);
  }

  getRejectReasonHistory(data) {
    const date = stCommon.formatDate(data['date']);
    const rejectFiles = stCommon.getLastRejectFiles(data);
    const rejectListItemData = stCommon.toListItemData(rejectFiles, true);
    const rejectItemHeader = stCommon.initListItemHeaderOption('รูปภาพการปฏิเสธงานจากลูกค้า');
    const rejectListItem = stCommon.getListItem(rejectItemHeader, rejectListItemData, () => { });

    return (
      <div className="job-info">
        <div className="title">
          <p>รายละเอียดงานที่แก้ไข</p>
        </div>
        <div className="row">
          <EnTextLabel label="สถานะ" value="ปรับปรุงงาน" icon="fa fa-thumbs-o-down" />
          <EnTextLabel label="เนื่องจาก" value={data['reject_reason']} icon="fa fa-comments-o" />
          <EnTextLabel label="เมื่อวันที่" value={date} icon="fa fa-clock-o" />
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            {rejectListItem}
          </div>
        </div>
        <div className="row">
          <div className='col-md-12'>
            <EnButton className="btn-info pull-left"
              onClick={this.onOpenShowReject.bind(this)} >
              <i className="fa fa-image btn-icon" aria-hidden="true" />แสดงรูปทั้งหมด
            </EnButton>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  getOptions(data) {
    const options = data.map((value, index) => {
      return {
        title: stCommon.getTitleOptions(data, index),
        active: stCommon.getActiveClass(index),
        content: () => {
          return (
            this.getOptionsContent(data, index)
          );
        },
      };
    });

    return options;
  }
  onOpenAllBefore() {
    try {
      this.setState({ openModalBefore: true });
    } catch (error) {
      // onError(error.message);
    }
  }
  onOpenAllStart() {
    try {
      this.setState({ openModalStart: true });
    } catch (error) {
      // onError(error.message);
    }
  }
  onCloseModal = () => {
    this.setState({ openModalStart: false, openModalBefore: false, openModalOnsite: false, openModalReject: false });
  };

  onToggleDropZone = () => {
    this.setState({ showChargeDropzone: !this.state.showChargeDropzone });
  }

  async getMapFilesCustomer(jobId) {
    const jobStore = this.props.job.toJS();
    await jobStore.getOrderByJobId(jobId);
  }

  showDocumentButton(
    buttonType,
    requiredButton,
    isSuperAdminMode,
    isStoreMode,
    isJobShare,
    jobInfo
  ) {
    let isShowButton = false;
    switch (buttonType) {
      case 'quotation':
        if (
          requiredButton &&
          !isJobShare &&
          (isSuperAdminMode || isStoreMode) &&
          jobInfo.jobType === 'I' &&
          jobInfo.original
        ) {
          isShowButton = true;
        }
        break;
      case 'po':
        if (
          requiredButton &&
          !isJobShare &&
          (isSuperAdminMode || isStoreMode) &&
          jobInfo.jobType === 'S' &&
          jobInfo.cost &&
          jobInfo.cost.survey &&
          jobInfo.sku &&
          jobInfo.po_survey
        ) {
          isShowButton = true;
        }
        break;
      case 'reciept':
        if (
          requiredButton &&
          !isJobShare &&
          (isSuperAdminMode || isStoreMode) &&
          jobInfo.is_paid
        ) {
          isShowButton = true;
        }
        break;
      default:
        isShowButton = false;
        break;
    }
    return isShowButton;
  }

  async gotoCreateQuotationPage() {
    const jobInfo = this.props.job.toJS().info;
    const { id } = jobInfo;
    await this.props.job.showJobUpdatedDialog(
      jobInfo,
      () => history.push(`${config.publicUrl}/superadmin/jobs/${id}/quotation?create=true`)
    );
  }

  handleHideButton(jobInfo, buttonName) {
    if (!buttonName) return false;
    const status = jobInfo.status === 'paid_to_contractor' ? jobInfo.status : jobInfo.customer_approved ? "customer_approved" : jobInfo.status;
    const saleModel = jobInfo.saleModel ? jobInfo.saleModel.toLowerCase() : '';
    switch (saleModel) {
      case "marketplace":
        const marketPlaceHideButton = MarketPlaceHideButton.find(
          (value) => value.jobType === jobInfo.jobType
        );
        return (
          marketPlaceHideButton &&
          marketPlaceHideButton.button[buttonName] &&
          marketPlaceHideButton.button[buttonName].includes(status)
        );
      case "marketplacecredit":
        const marketPlaceCreditHideButton = MarketPlaceCreditHideButton.find(
          (value) => value.jobType === jobInfo.jobType
        );
        return (
          marketPlaceCreditHideButton &&
          marketPlaceCreditHideButton.button[buttonName] &&
          marketPlaceCreditHideButton.button[buttonName].includes(status)
        );
      case "retail":
        const retailHideButton = RetailHideButton.find(
          (value) => value.jobType === jobInfo.jobType
        );
        return (
          retailHideButton &&
          retailHideButton.button[buttonName] &&
          retailHideButton.button[buttonName].includes(status)
        );
      default:
        const defaultHideButton = MarketPlaceHideButton.find(
          (value) => value.jobType === jobInfo.jobType
        );
        return (
          defaultHideButton &&
          defaultHideButton.button[buttonName] &&
          defaultHideButton.button[buttonName].includes(status)
        );
    }
  }

  getOptionsContent(data, index) {
    const jobStore = this.props.job.toJS();
    const jobInfo = jobStore.info;
    const orderId = jobStore.orderId;

    const { contractorMode, statusClaim } = this.props;
    const isLastversion = (index === 0);

    let historyNextStep = stCommon.filterHistoryStatus(jobInfo.statusHistory, this.state.nextStep);
    historyNextStep = stCommon.sortDataByDate(historyNextStep);
    let installationBeforeFilesData = stCommon.toListItemData(jobInfo.installationBeforeFiles);
    let listHeaderInstallationBefore = stCommon.initListItemHeaderOption('รูปถ่ายหรือไฟล์แนบก่อนติดตั้ง');
    let listItemBefore = stCommon.getListItem(listHeaderInstallationBefore, installationBeforeFilesData, stCommon.onUploadItemClick.bind(this, 'installationBeforeFiles', jobInfo));
    listHeaderInstallationBefore.push(stCommon.listItemHeaderOpts);

    let itemHeader = stCommon.initListItemHeaderOption('รูปถ่ายหรือไฟล์แนบหลังติดตั้ง ( อัพโหลดได้สูงสุด 20 ไฟล์ต่อครั้ง )');
    if (isLastversion) {
      itemHeader.push(stCommon.listItemHeaderOpts);
    }

    let rejectReason = '';
    let rejectReasonHistory = '';
    if (data[index]['reject_reason']) {
      if (jobInfo.status === this.state.currentStep && index === 0) {
        rejectReason = this.getRejectReason(data[index]);
      } else {
        rejectReasonHistory = this.getRejectReasonHistory(data[index]);
      }
    }

    let itemData = stCommon.toListItemData(jobInfo[this.state.files]);
    let listItem = stCommon.getListItem(itemHeader, itemData, stCommon.onUploadItemClick.bind(this, this.state.files, jobInfo));
    let remark = jobInfo[this.state.remark];
    let indexHistory = index;
    let itemChargeHeader = stCommon.initListItemHeaderOption('');
    let itemChargeData = stCommon.toListItemData(jobInfo[this.state.chargeFile]);
    let listChargeItem = stCommon.getListItem(itemChargeHeader, itemChargeData, stCommon.onUploadItemClick.bind(this, this.state.chargeFile, jobInfo));
    itemChargeHeader.push(stCommon.listItemHeaderOpts);
    let onsitePictureHeader = stCommon.initListItemHeaderOption(
      'รูปภาพและวีดีโอหน้างานจากลูกค้า ( อัพโหลดได้สูงสุดไม่เกิน 10 ไฟล์ )'
    );
    const imagesOnsite = jobStore.onsitePicture ? jobStore.onsitePicture[0].file : [];
    const videosOnsite = jobStore.onsitePicture ? jobStore.onsitePicture[1].file : [];
    const allFiles = [...imagesOnsite, ...videosOnsite];
    let itemFileNameCustomer = stCommon.toListItemData(allFiles);
    let listOnsitePicture = stCommon.getListOnsitePicture(
      onsitePictureHeader,
      itemFileNameCustomer,
      stCommon.onDeleteOnsite.bind(this, orderId)
    );
    onsitePictureHeader.push(stCommon.listItemHeaderOpts);
    if (!isLastversion) {
      if (data.length > historyNextStep.length) {
        indexHistory = index - 1;
      }

      itemData = stCommon.toListItemData(historyNextStep[indexHistory]['files']);
      remark = historyNextStep[indexHistory]['remark'];

      listItem = stCommon.getListItem(itemHeader, itemData, () => { });
    }

    // dropzone for upload file
    let dropzoneOnsite = stCommon.getDropZoneOnsitePicture(
      e => { this.dropzone = e; },
      (e) => {
        stCommon.onUploadOnsiteComplete(e, orderId);
      },
      this.onAddedOnsiteFile.bind(this),
      "",
      statusClaim
    );

    let dropzone = stCommon.getDropZone(
      e => { this.dropzone = e; },
      this.props.jobId,
      (e) => {
        stCommon.onUploadComplete(e, this.state, this.props.contractorMode, this.props.onSubmit);
      },
      this.onAddedInstallationFile.bind(this),
      "",
      statusClaim
    );

    const notiAdditionChargeToAdmin = true;
    const dropzoneChargeMessage = 'กรุณาเลือกใบเสร็จค่าใช้จ่าย';
    let dropzoneCharge = stCommon.getDropZone(e => { this.dropzone = e; }, this.props.jobId, (e) => {
      stCommon.onUploadComplete(e, this.state, this.props.contractorMode, this.props.onSubmit, this.state.chargeFile, notiAdditionChargeToAdmin);
    }, undefined, dropzoneChargeMessage, statusClaim, false);

    const { openModalBefore } = this.state;
    const imgsBefore = installationBeforeFilesData.map((item, idx) => {
      return (
        <div className="row col-md-12">
          <div className="row" style={{ paddingLeft: '16px' }}>
            <h4>{(idx + 1)}</h4>
          </div>
          <div className="row col-md-12" style={{ textAlign: 'center' }}>
            <img src={item.url} alt="" style={{ textAlign: 'center' }} height={300} mode="fill" />
          </div>
        </div>
      );
    });
    const { openModalStart, openModalOnsite, openModalReject } = this.state;
    // const userInfo = this.props.auth.getUserInfo();
    // const channel = userInfo.channel ? userInfo.channel.channelName : '';
    const channel = this.props.auth.getChannelName();
    let isSCGHome = (channel === 'SCGHOME Online');
    const imgsStart = itemData.map((item, idx) => {
      return (
        <div className="row col-md-12">
          <div className="row" style={{ paddingLeft: '16px' }}>
            <h4>{(idx + 1)}</h4>
          </div>
          <div className="row col-md-12" style={{ textAlign: 'center' }}>
            <img src={item.url} alt="" style={{ textAlign: 'center' }} height={300} mode="fill" />
          </div>
        </div>
      );
    });
    const showImgsOnsite = imagesOnsite.map((item, idx) => {
      return (
        <div className="row col-md-12">
          <div className="row" style={{ paddingLeft: '16px' }}>
            <h4>{(idx + 1)}</h4>
          </div>
          <div className="row col-md-12" style={{ textAlign: 'center' }}>
            <img src={item.url} alt="" style={{ textAlign: 'center' }} height={300} mode="fill" />
          </div>
        </div>
      );
    });
    const imgsReject = data[index].reject_files;
    const showImgsReject = (imgsReject || []).map((item, idx) => {
      if (item.type === 'image') {
        return (
          <div className='row col-md-12' >
            <div className="row col-md-12" style={{ paddingLeft: '16px' }}>
              <h4>{(idx + 1)}</h4>
            </div>
            <div className="row col-md-12"  >
              <img src={item.url} height={300} />
            </div>
          </div>
        );
      }
    });

    const store = authStore.getStore();
    // const hasSubsidize = !(jobInfo.subsidizePromotion && jobInfo.subsidizePromotion.is_paid === true) && this.state.subsidize_amount > 0;
    // const showResendSmsToCustomerButton = ['installation_finished'].includes(jobInfo.status);
    // const trackingQueryString = !contractorMode && (hasSubsidize || showResendSmsToCustomerButton) ? '?user=admin' : '';
    // let showAddQuotation = false;
    let isShowQuotationButton = false;
    let storeMode = !!store;

    if (!contractorMode) {
      isShowQuotationButton = this.showDocumentButton(
        'quotation',
        authStore.isSuperAdmin() ? true : store && store.quotation_require,
        authStore.isSuperAdmin(),
        storeMode,
        false,
        jobInfo
      );
    }
    
    // if (jobInfo.quotation && jobInfo.quotation.length) {
    //   const checkQuotationPaid = !!jobInfo.quotation[
    //     jobInfo.quotation.length - 1
    //   ].periods.find((p) => p.status === 'paid');
    //   showAddQuotation =
    //     isShowQuotationButton &&
    //     checkQuotationPaid &&
    //     jobInfo.status !== 'paid_to_contractor' &&
    //     jobInfo.is_paid;
    // }

    return (
      <div>
        {
          !_.isEmpty(rejectReason) && rejectReason
        }
        {stCommon.showBasicInfo(jobInfo, contractorMode)}
        {
          !_.isEmpty(rejectReasonHistory) && rejectReasonHistory
        }
        <div className="col-md-12">
          <Modal style={{ textAlign: 'center' }} open={openModalBefore} onClose={this.onCloseModal} center>
            {imgsBefore}
          </Modal>
        </div>
        <div className="col-md-12">
          <Modal style={{ textAlign: 'center' }} open={openModalStart} onClose={this.onCloseModal} center>
            {imgsStart}
          </Modal>
        </div>
        <div className="col-md-12">
          <Modal style={{ textAlign: 'center' }} open={openModalOnsite} onClose={this.onCloseModal} center>
            {showImgsOnsite}
          </Modal>
        </div>
        <div className="col-md-12">
          <Modal style={{ textAlign: 'center' }} open={openModalReject} onClose={this.onCloseModal} center>
            {showImgsReject}
          </Modal>
        </div>
        <div className="row">
          <div className='col-md-12'>
            {listOnsitePicture}
            {
              // contractorMode && (
              dropzoneOnsite
              // )
            }
          </div>
        </div>
        <div className="row">
          <div className='col-md-12'>
            <EnButton className="btn-info pull-left" onClick={this.onOpenShowOnsite.bind(this)}>
              <i className="fa fa-image btn-icon" aria-hidden="true" />แสดงรูปทั้งหมด
            </EnButton>
          </div>
        </div>
        <hr />
        <div className="row wordWarp">
          <div className="col-md-6">
            {listItemBefore}
          </div>

          <div className="col-md-6">
            <FormGroup label="หมายเหตุก่อนติดตั้ง">
              {jobInfo.installationBeforeRemark ? <div>{jobInfo.installationBeforeRemark}</div> : stCommon.getDisplayEmpty('-')}
            </FormGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <EnButton className="btn-info pull-left" onClick={this.onOpenAllBefore.bind(this)}>
              <i className="fa fa-image btn-icon" aria-hidden="true" />แสดงรูปทั้งหมด
            </EnButton>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            {listItem}
            {
              // contractorMode &&
              isLastversion && (
                dropzone
              )
            }
            <EnButton className="btn-info pull-left" onClick={this.onOpenAllStart.bind(this)}>
              <i className="fa fa-image btn-icon" aria-hidden="true" />แสดงรูปทั้งหมด
            </EnButton>
          </div>
        </div>
        &nbsp;
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div style={{ margin: '0 4px' }}><b>ค่าใช้จ่ายเพิ่มเติม และเอกสารอื่น ๆ</b></div>
                <EnButton className={'btn-success'} onClick={this.onToggleDropZone.bind(this)} disabled={statusClaim}>
                  เพิ่มค่าใช้จ่ายอื่นๆ
                </EnButton>
              </div>
            </div>
            {listChargeItem}
            {this.state.showChargeDropzone && dropzoneCharge}
          </div>
        </div>
        &nbsp;
        {
          // contractorMode && (
          <div className="row">
            <div className="col-md-12">
              <EnTextAreaFormGroup label="หมายเหตุหลังติดตั้ง" rows="5" value={remark} onChange={stCommon.saveInfo.bind(this, this.state.remark)} disabled={!isLastversion || statusClaim} />
            </div>
          </div>
          // )
        }
        {/* {
          !contractorMode && (
            <div>
              <div className="row">
                <div className="col-md-12">
                  <FormGroup label="หมายเหตุหลังติดตั้ง">
                    {remark ? <div>{remark}</div> : stCommon.getDisplayEmpty('-')}
                  </FormGroup>
                </div>
              </div>
            </div>
          )
        } */}
        <div className="row">
          <div className="col-md-12">
            {this.state.orderId && !contractorMode && isAllow("VIEW_JOB_TRACKING_PROCESS") && (
              <div>
                <a
                  href={this.state.trackingUrl}
                  target='_blank'
                >
                  <i className='fa fa-truck' aria-hidden='true' />
                  &nbsp;&nbsp;ตรวจสอบสถานะ
                </a>
                &nbsp;&nbsp;<a onClick={() => this.copyToClipboard()} style={{ cursor: 'pointer' }}>
                  <i className='fa fa-copy' aria-hidden='true' />
                  &nbsp;&nbsp;คัดลอก
                </a>
              </div>
            )}
            {isAllow("CREATE_AND_EDIT_QUOTATION") 
            ? !this.handleHideButton(jobInfo, 'addQuotation') && (
              <EnButton
                id='open-quotation-button'
                className='btn-success  btn-quotation'
                onClick={this.gotoCreateQuotationPage.bind(this)}
                disabled={this.props.statusClaim}
                style={{ margin: '10px 0' }}
              >
                <i className='fa fa-plus' aria-hidden='true' />
                &nbsp; เพิ่มใบเสนอราคา
              </EnButton>
            ) : <div></div>}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {
              (!isSCGHome) && ((isLastversion && jobInfo.status === this.state.currentStep) &&
                stCommon.getDisplayStatusButton(jobInfo.jobType, jobInfo.status, contractorMode, async () => { await this.onClickNextState(jobInfo.jobType, jobInfo.status, jobInfo.installationAfterFiles); }, '', true, undefined, true))
            }
            {
              contractorMode && isLastversion && (
                <EnButton id="submit-button" className="btn-info pull-right" onClick={this.onSubmit.bind(this)}>
                  <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                </EnButton>
              )
            }
            <EnButton id="back-button" className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
              <i className="fa fa-reply btn-icon" aria-hidden="true" />กลับ
            </EnButton>
          </div>
        </div>
      </div>
    );
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.state.trackingUrl);
  }

  render() {
    const jobStore = this.props.job.toJS();
    const jobInfo = jobStore.info;

    let statusHistory = stCommon.filterHistoryStatus(jobInfo.statusHistory, this.state.currentStep);
    statusHistory = stCommon.sortDataByDate(statusHistory);

    const options = this.getOptions(statusHistory);
    const isOnlyStatus = options.length < 2;

    return (
      <div>
        <EnTabContent options={options} className="tab-right" displayNone={isOnlyStatus} />
      </div>
    );
  }
}

export default inject('auth', 'job')(observer(EnInstalledStart));
