import React, { Component } from 'react';
import * as _ from 'lodash';
import { control } from 'react-validation';
// import TextArea from 'react-validation/build/textarea';

const SimsTextArea = control(
  React.forwardRef(({ error, isChanged, isUsed, ...props }, ref) => {
  const textAreaProps = _.cloneDeep(props);
  let className = textAreaProps.className || '';
  delete textAreaProps.className;

  if (error) {
    // className += ' is-invalid-input';
  }

  return (
    <div>
      <textarea ref={ref} {...props} className={className} />
      {isChanged && isUsed && error}
    </div>
  );
}))

export class EnTextAreaV2 extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    if(this.props.autoHeight !== true) return;
    setTimeout(() => {
      this.adjustHeight();  
    });
  }

  adjustHeight = () => {
    if (!this.inputRef.current._reactInternalFiber.child) return;

    const textarea = this.inputRef.current._reactInternalFiber.child.child.child.stateNode;

    if (!textarea) return;
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  };

  render() {
    let propInput = Object.assign({}, this.props);
    propInput.className = `${propInput.className ? propInput.className : ''} form-control`;

    if (propInput.validations && propInput.validations.length) {
      return <SimsTextArea style={propInput.style} ref={this.inputRef} {...propInput} />;
    } else {
      return <textarea style={propInput.style} ref={this.inputRef} {...propInput} />;
    }
  }
}

export default EnTextAreaV2;
